import React, { useState, useEffect } from "react";
import "./App.css";
import "./style.css";
import "@aws-amplify/ui-react/styles.css";
//import { API } from "aws-amplify";
// import { Storage, API, graphqlOperation, Auth } from "aws-amplify";
import {  API,  Auth } from "aws-amplify";
//import { Authenticator } from '@aws-amplify/ui-react';
// CONTROL BACKTICK (which is directly above CONTROL) 4 TERMINAL for npm start!  

import initializeAnalytics from './analytics';

import 'bootstrap/dist/css/bootstrap.min.css';
import {
  View,
  Authenticator
} from "@aws-amplify/ui-react";
//  OTHER UI-REACT: Button, Flex, Heading,Text, TextField, useAuthenticator, withAuthenticator

//import { Notes } from './components/Notes';
import { ProtectedCreate } from './components/Protected-Create';
import { RequireAuth } from './RequireAuth';
import { RequireGroup } from './components/RequireGroup';
import { Login } from './components/Login';
import { AdminGate } from './components/AdminGate';
import { AdminContent } from './components/AdminContent';
import { PersonalFountains } from './components/UnProtected-ListFountains';
//import { Test } from './components/Test';
import { Map } from './components/Map';
import { Home } from './components/Home';
import { Layout } from './components/Layout';
import { Slideshow } from './components/Slideshow';
import { Dropdown } from './components/Dropdown';
// npm install RRM ... 
// BR ...
import { BrowserRouter, Routes, Route , useLocation } from 'react-router-dom';
//import { useNavigate, useLocation } from 'react-router'; //npm i react-router-dom@6
// queries & mutations
import { listDersonalFountains } from "./graphql/queries";
//import {
////  createPersonalFountain as createPersonalFountainMutation,
//  deletePersonalFountain as deletePersonalFountainMutation,
//} from "./graphql/mutations";

// REMEMBER NO DOUBLE SPACES AS IT CONFUSES ERROR MESSAGES!!! 
import { Search} from './components/Search'

import ReactGA from "react-ga4";  // Import ReactGA at the top

const TRACKING_ID = "G-GPCJZG2MRT"; // Replace with your GA4 Measurement ID



function MyRoutes() {

  const [fountains, setPersonalFountains] = useState([]);

  // should this be called from App.js or PersonalFountains?
  // DEFINED HERE BUT DOESN'T NEED TO BE CALLED HERE! 
  async function fetchPersonalFountains() {

    console.log("This is fetchPersonalFountains - is it USED???");

    let fountainsFromAPI;

    // TRY CATCH FOR IF LOGGED IN OR NOT ...
    // Not entirely clear why fountains are needed on this page! 
    try {
      await Auth.currentAuthenticatedUser();
      console.log("A user is logged on! Use Cog User Pool as authMode!")
      //return true;
      const apiData = await API.graphql({ query: listDersonalFountains , authMode:"AMAZON_COGNITO_USER_POOLS" });
      fountainsFromAPI = apiData.data.listDersonalFountains.items;
      console.log("First fountain returned is: ");
      console.log(fountainsFromAPI[0]);
      setPersonalFountains(fountainsFromAPI);
  } catch {
      //return false;
      console.log("User NOT logged in. User IAM as provider in authMode:");
      const apiData = await API.graphql({ query: listDersonalFountains,
       authMode:"AWS_IAM"
      });
      fountainsFromAPI = apiData.data.listDersonalFountains.items;
      console.log("First fountain returned is: ");
      console.log(fountainsFromAPI[0]);
      setPersonalFountains(fountainsFromAPI);
  }

   

 
   
  }
  // MyRoutes component in App.js returns BROSWERROUTE containing Routes with paths and elements
   // NEW PAGE? ... Add to BR BrowserRouter BR
  return (
    <BrowserRouter>
     {/* ROUTES PLURAL ... */}
      <Routes>

        {/* RPE - ROUTE PATH ELEMENT .. path should match whats in layout.js */}
         {/* BELOW is where Layout comp is called!!!  */}

           {/* I think saing path / makes Layout appear on EVERY PAGE  */}
        <Route path="/" element={<Layout />}>

          <Route index element={<Home />} />

          <Route path="/map" element={<Map />} />

          <Route path="/slideshow" element={<Slideshow />} />


          <Route path="/dropdown" element={<Dropdown />} />

      {/* RPE - ROUTE PATH ELEMENT .. path should match whats in layout.js */}
        <Route
          path="/protected"
          element={
            <RequireAuth>
              <ProtectedCreate fetchPersonalFountains={fetchPersonalFountains}/>
            </RequireAuth>
          }
        />

       {/* RPE - ROUTE PATH ELEMENT .. path should match whats in layout.js */}
        {/* Below in App.js MYROUTES is where PersonalFountains comp is called */}
      <Route
          path="/fountains"
          element={
           
              <PersonalFountains />
         
          }
        />  




         {/* RPE - ROUTE PATH ELEMENT .. path should match whats in layout.js*/}
        <Route
          path="/admin"
          element={
            <RequireAuth>
              <RequireGroup>
                <AdminContent></AdminContent>
                </RequireGroup>
            </RequireAuth>
          }
        />
         {/* RPE - ROUTE PATH ELEMENT */}
        <Route path="/login" element={<Login />} />
      </Route>

    </Routes>
  </BrowserRouter>
  );
} // end of func MyRoutes that returns a BrowserRouter object!

// END OF MYROUTES !!! 


const AnalyticsTracker = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return children;
};


// CONST APP ... 
// THIS IS APP.js ... 
// signout is a PROP present from parent 
const App = ({ signOut }) => {

  //const [fountains, setPersonalFountains] = useState([]);

  //const [elements, setElements] = useState([]);

  useEffect(() => {
    //fetchPersonalFountains();
  }, []);


  useEffect(() => {
    console.log("initializeAnalytics");
    initializeAnalytics(TRACKING_ID);
  }, []);



  // returns just a view with Auth Provider and MyRoutes!
  return (
    <>
    <script src="js/script.js"></script>
    <View className="App" id="verytoplevel">
      {/* <Heading level={1}>The Drinking Fountain Association</Heading> */}
      {/*<h1> The Drinking Fountain Association</h1>
      loginMechanisms={['email','phone_number']
      <Authenticator.Provider
      */}
      {/*<Authenticator loginMechanisms={['phone_number']}>*/}
     <Authenticator.Provider>
     <MyRoutes />
     </Authenticator.Provider>
    {/*</Authenticator> */}
    
    </View>


  </>
  );
};




export default App;