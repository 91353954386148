import React from 'react';
//import './YourStyleSheet.css'; // Replace with your actual stylesheet path

const GrantsFAQs = () => {
    return (




<div class="section pt-0" id="faqs">
        <div class="container">
            <div class="section-title">
                <small>FAQ</small>
                <h3>Grants: Frequently Asked Questions</h3>
            </div>

            <div class="row pt-4">
                <div class="col-md-6">
                    <h4 class="mb-3">What are the Association's Objectives?</h4>
                    <p class="light-font mb-5">To receive a grant your project will need to meet the Association's Objectives which are: (1) The promotion of the provision of drinking water for people and animals in the United Kingdom and Overseas. (2) The preservation of the Association's archive materials, artefacts, drinking fountains, cattle troughs and other installations. The main criteria for considering a grant to a non-UK project are that they would provide drinking water for people and animals, and the charity requesting the grant is registered in the UK. </p>
                    <h4 class="mb-3">What information do I need to provide?</h4>
                    <p class="light-font mb-5">When requesting a grant, we'll ask you for (1) A copy of the most recent audited accounts. (2)How has the cost of the project been ascertained, e.g. qualified surveyor etc,. (3) How many people/animals is it estimated would use the fountain/trough in a day (4) Will the charity supervise the project, if not who would? (5) Where is it anticipated the remainder of the funds to complete the project will come from.</p>

                </div>
                <div class="col-md-6">
                    <h4 class="mb-3">What model of fountain do you fund?</h4>
                    <p class="light-font mb-5">For schools we fund the  <a href="forms/NOVUS%20FLYER.pdf">Novus Fountain</a>. Maintenance details can be found <a href="forms/Novusdetailcard.doc"> here</a> . </p>
                    
                    <h4 class="mb-3">What other advice do you have?</h4>
                    <p class="light-font mb-5">It helps to have support from your local authority if you are trying to get a fountain installed. We recommend fountain models with downward facing spouts.  </p>

                    <h4 class="mb-3">What is the full name of the Association?</h4>
                    <p class="light-font mb-5">The full name of the Association is the The Metropolitan Drinking Fountain and Cattle Trough Association.  </p>


                </div>

            

            </div>
        </div>
    
    </div>
 


    );
};

export default GrantsFAQs;
