// components/Home.js// components/Home.js
import React, { useState, useEffect, useMemo } from "react"; //useMemo for media queries? 
import { Outlet, useNavigate } from 'react-router-dom'; //RRD
import "../App.css";
import "@aws-amplify/ui-react/styles.css";
// EXPORT MARKS THE SPOT!!! 

// npm install styled-components
// 
// IMPORTANT LEARNING - if you go to "Computed" styles in Dev tools and see
// <style> as the SOURCE of a style then its becuase of these styled-components.

import styled from "styled-components";

// REMEMBER NO DOUBLE SPACES AS IT CONFUSES ERROR MESSAGES!!! 
import { Search} from './Search'

//import { API } from "aws-amplify"; = AppSync
// AA
import { Storage, API, Auth, graphqlOperation } from "aws-amplify";

// AA-UR
// import { Heading } from '@aws-amplify/ui-react';
import {
  Button,
  Flex,
  Image,
  Heading,
  Text,
  TextField,
  View,
  withAuthenticator,
  useAuthenticator
} from "@aws-amplify/ui-react";

// queries & mutations
//import { listPersonalFountains } from "../graphql/queries";
// BELOW is where the GraphQL queries come from.
import { fountainsSortedByCityTown, getDersonalFountain, byBotoHashAndStreetNameSortKey, listPersonalFountains } from "../graphql/queries";
// CUSTOM PAUL QUERIES (not autogenerated by add api)
import { listFountainsLimitedHeaders, fountainsSortedByDistrictLimitedAttributes, fountainsSortedByCityTownLimitedAttributes } from "../graphqlpocqueries/pocqueries";


import {
  createDersonalFountain as createPersonalFountainMutation,
  deleteDersonalFountain as deletePersonalFountainMutation,
  updateDersonalFountain as updatePersonalFountainMutation
} from "../graphql/mutations";


import { device } from './device';
// TEST LOCALLY with npm start

const placeholderDistrict = "Camden";
const hardDistricts=[
  "London County",
  "Barking and Dagenham",
  "Barnet",
  "Bexley",
  "Brent",
  "Bromley",
  "Camden",
  "City of London",
  "Croydon",
  "Ealing",
  "Elmbridge",
  "Enfield",
  "Greenwich",
  "Hackney",
  "Hammersmith and Fulham",
  "Haringey",
  "Harrow",
  "Havering",
  "Hillingdon",
  "Hounslow",
  "Islington",
  "Kensington and Chelsea",
  "Kingston upon Thames",
  "Lambeth",
  "Lewisham",
  "Merton",
  "Newham",
  "Redbridge",
  "Richmond upon Thames",
  "Southwark",
  "Sutton",
  "Tower Hamlets",
  "Waltham Forest",
  "Wandsworth",
  "Westminster"
];
const hardCities=["Dublin","Croydon","Edinborough","London", "Liverpool", "Manchester", "Unknown"];


// inline = IN THE SAME LINE , NO NEW LINE BELOW
// NOTE THE SUBTLE BACKTICK
// display: inline-block; // width determined by contents. Only as big as contents
// display flex // width gobbles up availabe space. 
// width: 6em;


// IMPORTANT LEARNING - if you go to "Computed" styles in Dev tools and see
// <style> as the SOURCE of a style then its becuase of these styled 

const DropDownContainer = styled("div")`

  display: flex;
  flex-flow: column;
  margin: 0 auto;
`;

var fountId ="1234";


//const DropDownContainer = styled("div")`
//  display: flex;
//  flex-flow: column;
//  justify-content;
//`;


// IMPORTANT LEARNING - if you go to "Computed" styles in Dev tools and see
// <style> as the SOURCE of a style then its becuase of these styled 

const DropDownHeader = styled("div")`
  margin-bottom: 0.1em;
  padding: 0.4em 0em 0.4em 0em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  color: #3faffa;
  background: #ffffff;

  @media ${device.mobilepoc} { 
    font-size: 1.3rem;
  }

  @media ${device.threehundredandsixty} { 
    font-size: 1rem;
  }

  font-size: 0.8rem;
`;


const DropDownListContainer = styled("div")``;


// IMPORTANT LEARNING - if you go to "Computed" styles in Dev tools and see
// <style> as the SOURCE of a style then its becuase of these styled 

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 0em;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: #3faffa;
  font-size: 1.3rem;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.4em;
`;


const HistoryOf = styled("div")`
display: inline-block;
  width: 7em;
  margin: 0 auto;
  margin-bottom: 0.1em;
  padding: 0.4em 0em 0.4em 0em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
 
  color: #000000;
  background: #ffffff;


  @media ${device.mobilepoc} { 
    font-size: 1.3rem;
  }

  @media ${device.threehundredandsixty} { 
    font-size: 1rem;
  }

  font-size: 0.8rem;
`;


// FOR MOBILE RESPONSIVE DESIGN ...
// DEFINE UMQ SINGULAR! ... called later from export function CompName()
// Use PASS IN a media query such as (min-width: 800px)
function useMediaQuery(query) {

  
  const mediaQuery = useMemo(() => window.matchMedia(query), [query]);
  
  // pass this particular media query into state var match
  // match represents IS THIS PARTICULAR screen width check true or false? 
  const [match, setMatch] = useState(mediaQuery.matches);

  // Get media query status - whether the query matches the current viewport.
  // Subscribe to the changes of media query status.
  useEffect(() => {
    const onChange = () => {
      console.log("=======================");
      console.log("=====SCREEN CHANGE set up in UMQ sing.========");
      
      console.log("UE If the SCREEN WIDTH NARROWS or WIDENS this gets called!");
      console.log("UE inside UMQ SINGULAR onChange SCREEN WIDTH?");
      // WE CHANGE match when the screen changes!! 
      setMatch(mediaQuery.matches);
    }
    mediaQuery.addEventListener("change", onChange);

    return () => mediaQuery.removeEventListener("change", onChange);
    // THIS USEEFFECT HAS DEPENDENCY ON mediaQuery ...
  }, [mediaQuery]);

  // console.log(`UMQ SINGULAR for ${query} returns: ${match}`);
  // RETURN match which is a boolean true false? 
  console.log(`useMediaQuery SINGU - we check if window is  ${query}. Answer: ${match}`)
  return match;
}

// PLURAL ... from where SINGULAR called a few times per device type! 
// THIS IS CALLED BY OAF in export PersonalFountains
function useMediaQueries() {
  
  //console.log("USE MEDIA QUERIES PLURAL .. it calls singular a few times (1 per width) ...");
  
  // check if really small ...true/false
  const isMobile = useMediaQuery("(max-width: 760px)");
  
  console.log("We do a series of checks to see if window breaches certain set thresholds!")
  console.log("Check if at least 800 ...");
  const bool_window_is_at_least_800_md = useMediaQuery("(min-width: 800px)");
  
  const bool_window_is_at_least_992 = useMediaQuery("(min-width: 992px)");
  

  // Note 1200 is very wide! Hard to even trigger on macbook.
  const bool_window_is_at_least_at_least_1200_lg = useMediaQuery("(min-width: 1200px)");

  if(isMobile) {
    console.log("isMobile (i.e. at_most_760px) is");
    console.log(isMobile);
    console.log("MOBILE DEVICE!")
  } else {
    console.log("window not mobile (i.e. NOT at MOST 760");
  }

  //console.log("md_at_least_800 is");
  //console.log(md_at_least_800);
  //console.log("lg_at_least_1200 is");
  //console.log(lg_at_least_1200);

  // RETURN X NUMBER OF THINGS LISTED BELOW ...
  return { isMobile, md_at_least_800: bool_window_is_at_least_800_md, menu_visible: bool_window_is_at_least_992, lg_at_least_1200: bool_window_is_at_least_at_least_1200_lg };
}




// Formerly UnProtected Component.
// EXPORT - THIS IS THE MAIN FUNC FOR THE PAGE ...
// EXPORT MARKS THE SPOT!!! 
// export function PersonalFountains({ signOut }) {
export function PersonalFountains() {
//const Unp = ({ signOut }) => {
  console.log("sTHIS IS PersonalFountains UnProtected COMPONENT!");
  console.log("x x x x x x x x x x x x x x x ");
  console.log("x x x x x x x x x x x x x x x ");
  console.log("x x x x x x x x x x x x x x x ");
  console.log("x x x x x x x x x x x x x x x ");
  //console.log("useEffect will only be called if 1st mount OR change to specified depenedencies eg., nextToken!");
  
  // OAF in react = outside functions that are inside the main function Dropdown()
  console.log("OAF! - EXPORT MARKS THE SPOT- function PersonalFountains()");
  console.log("OAF! - EXPORT MARKS THE SPOT function PersonalFountains()");
  console.log("EXPORT MARKS THE SPOT");
  console.log("A NEW ROUND OF RERENDERING has been started!!!");

  // At the beginning of the PersonalFountains component, add a new state hook for the search term:
  const [searchTerm, setSearchTerm] = useState('');
  
  // KEY CONCEPT - Apart from the first render, on subsequent re-renders only code in OAF gets called plus return HTML.
  // Post first render, Code inside top level UseEffects will only get called if the state they are dependent on changes due to setX(new value) being called
  
  
  
  // SET UP STATE !!! VIP VIP VIP
  const [notes, setNotes] = useState([]);

  // KEY CONCEPT - Apart from the first render, on subsequent re-renders only code in OAF gets called plus return HTML.
  // Post first render, Code inside top level UseEffects will only get called if the state they are dependent on changes due to setX(new value) being called
  
  // STATES .... initial values passed into useState() ONLY set on 1st render. Ignored in subsequent renders
  
  //const [fountains, setPersonalFountains] = useState([]);
  const [fountainsToDisplay, setFountainsToDisplay] = useState([]);
  const [apiListFountains, setApiListFountains] = useState([]);
 
  const [headers, setHeaders] = useState([tableHeaders]);

  const [elements, setElements] = useState([]);

  const [isDebugMode, setIsDebugMode] = useState(true);

  const [isSingleFountainView, setIsSingleFountainView] = useState(false);
  const [singleFountainId, setSingleFountainId] = useState(null);
  const [singleFountainObject, setSingleFountainObject] = useState(null);
  
  const [singleFountainFullImageUrl, setSingleFountainFullImageUrl] = useState(null);
  
  
  const [isEditView, setIsEditView] = useState(false);
  const [hideEditDeleteButtonsIfUnathenticated, setHideEditDeleteButtonsIfUnathenticated] = useState(true);

  const [nextToken, setNextToken] = useState(undefined)
  const [nextNextToken, setNextNextToken] = useState()
  const [previousTokens, setPreviousTokens] = useState([])



  // FOR DROPDOWN
  // You need to maintain different states for different dropdowns!
  const [isOpenTopics, setIsOpenTopics] = useState(false);
  const [isOpenDistricts, setIsOpenDistricts] = useState(false);
  const [isOpenCities, setIsOpenCities] = useState(false);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedCity, setSelectedCity] = useState("London");
  const [selectedTagOption, setSelectedTagOption] = useState(null);
  const ddOptionsTags = ["Everything", "Africa", "architecture","asia","books","business","central-america","china","crime","death","disasters","economics","environment","europe","exploration","justice","media","medicine","middle-east","movies","music","native-americans","new-york","politics","presidents","psychology","quotes","race","religion","russia","science","south-america","sport","tech","war","women","writers"];


  // TO DRIVE SIGN OUT BUTTON FUNCTIONALITY
  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const navigate = useNavigate();

  function logOut() {
    console.log("the func logOut has been called!");
    signOut();
    navigate('/login');
  }


   // FOR RESPONSIVE DESIGN - CALL useMediaQuries PLURAL!!! 
  // CALL UMQ PLURAL from inside export function ComponentName()
  //console.log("From inside export function CompName() call UMQ PLURAL ...");
  // Calling UMQ PLURAL will give values to these booleans ...
  // THIS IS CALLED outside any function (OAF) in export function PersonalFountains
  const {isMobile,md_at_least_800, menu_visible, global_lg_at_least_1200 } = useMediaQueries();
  // all we do above is gather info about the current window (plus set up some onChanges)

  const back2table = () => {
    console.log("THIS IS BACK2TABLE")
    setIsSingleFountainView(false);
    //navigate('/fountains')
    setIsEditView(false);
    setFountainsToDisplay(apiListFountains);

    // to get headers you can just look at first fountain and extract its headers.
    const tableHeadersFromApi = Object.keys(apiListFountains[0]);
    setHeaders(tableHeadersFromApi);
    //setHeaders
  }

  // Do not pass in because data may be stale. Safer to use states!
  const click2delete = async () => {
  //const click2delete = fountain => async () => {
    //console.log(`click2delete - ${fountain}`);
    console.log("click2delete");

    //console.log(Object.keys(fountain));
    //console.log(fountain.fountId);

    console.log(singleFountainId);

    //console.log(`singleFountainId stored state is ${singleFountainId}`);

    //const mutation2use = deletePersonalFountain

    console.log("Call ACAU Auth.currentAuthenticatedUser() to check if user logged in or not")
    console.log("There may be a DELAY in logs (out of sequence logging)");
    
    try {
      await Auth.currentAuthenticatedUser();
      console.log(`\nSuccessful currentAuthenticatedUser - implies logged into a Cognito User Pool!`);
      console.log("click2delete:FINALLY - After a DELAY ... ");
      console.log("click2delete: A user is logged on! Use Cog User Pool authMode");
      console.log("\nclick2delete: BIG MOMENT- call graphQl with query, variables and authMode !! ");
      //return true;

      deletePersonalFountain(singleFountainId);

     
  } catch {
      //return false for ;Auth.currentAuthenticatedUser() 
      console.log("User NOT logged in. User IAM as provider / authMode! in API call");
      
      alert(`NA NA Error - Unauthenticated NOT allowed to delete.\n`);
          
    
      } // end try catch for authenticated user.

  } // END click2delete




  // Do not pass in because data may be stale. Safer to use states!
  const click2edit = async () => {
    //const click2delete = fountain => async () => {
      //console.log(`click2delete - ${fountain}`);
      console.log("click2edit");
  
      //console.log(Object.keys(fountain));
      //console.log(fountain.fountId);
  
      console.log(singleFountainId);

      

  
      //console.log(`singleFountainId stored state is ${singleFountainId}`);
  
      //const mutation2use = editPersonalFountain
  
      console.log("Call ACAU Auth.currentAuthenticatedUser() to check if user logged in or not")
      console.log("DELAYS possible ...")
     
      try {
        await Auth.currentAuthenticatedUser();
        console.log("click2edit - Finalement - After a DELAY ... ");
        console.log("click2edit -A user is logged on! Use Cog User Pool authMode");
        console.log("\nclick2edit -BIG MOMENT- call graphQl with query, variables and authMode !! ");
        //return true;

        setIsEditView(true);
  
        //editPersonalFountain(singleFountainId);
  
       
    } catch {
        //return false for ;Auth.currentAuthenticatedUser() 
        console.log("User NOT logged in. User IAM as provider / authMode! in API call");
        
        alert(`NA NA Error - Unauthenticated NOT allowed to edit.\n`);
            
      
        } // end try catch for authenticated user.
  
    } // END click2edit
  

    // In function def, if not using props, do NOT forget the curly brackets! 
    // { DECONSTRUCTING }
    // https://react.dev/learn/passing-props-to-a-component
    // 
    function Drop( {ddid, subject, data} ) {

          // avoid Objects Are Not Valid as a React Child” Error
          console.log("func Drop component! - lets see what subject it is");
          
          var content2return;

          var ddContents = <div></div>;

          console.log("Lets check what subject is and IF OPEN. Go thru the cases!");
          console.log(`Subject passed into THIS Drop is ${subject}`);

          switch(true) {
            case subject === 'District' && isOpenDistricts === true :
            // we need the && subject === District because this common comp is used also for City's drop.  
            console.log("isOpenDistricts === true is TRUE call DropContentsWhenExpanded");
              console.log("Calling DropContents is what causes UX to show expanded content");
              ddContents = <DropContentsWhenExpanded data={data} subject={subject}></DropContentsWhenExpanded>;
              break;
            case subject === 'CityTown' && isOpenCities === true :
              console.log("isOpenCities === true is TRUE call DropContentsWhenExpanded");
              console.log("Calling DropContents is what causes UX to show expanded content");
              ddContents = <DropContentsWhenExpanded data={data} subject={subject}></DropContentsWhenExpanded>;
              break;
            case 1 > 2:
              console.log("switch false");
              ddContents = <div>YO</div>;
              break;
            default:
              console.log("DEFAULT! None of drops are currently open");
              ddContents = "";
        }

        console.log("EDN of drop cases checking!");

          content2return = 
          
      <DropDownContainer>
          <DropHeader4WhenClosed subject={subject}></DropHeader4WhenClosed>
            {ddContents}
      </DropDownContainer>

          // no smooth brackets if js variable ...
          return content2return;
   
      
    } // end of DROP


    function DropHeader4WhenClosed ( { subject } ) {

      //console.log("COMP DropHeader4WhenClosed");
      console.log(`COMP DropHeader4WhenClosed - Subject of Drop is ${subject}`);


      var headerTitle = "header title";
      
      if (subject ==="District") {
        console.log("DD Subject is districts so drop headerTitle should be selectedDistrict stored in state selectedDistrict OR, if that state is null, then subject (i.e. generic label District)");
        headerTitle = selectedDistrict || subject;

      } else if (subject ==="CityTown") {

      console.log("DD Subject is CityTown so drop headerTitle should be a SPECIFIC selectedCITY stored in state selectedCity (ie. a particular city) OR if that state is null, then subject (ie the label City)");
        headerTitle = selectedCity || subject;

      } else {
        console.log("Not sure what drops subject is so use default");
      }
      
      console.log("----------");
      let what2return=<DropDownHeader onClick={onClickDropdownHeadertoggleOpenCloseDrop({subject})}>
      {headerTitle}
      </DropDownHeader>

        
        return what2return;


      }




    function DropContentsWhenExpanded( {data, subject} ) {

      console.log("COMP DropContentsWhenExpanded");

      return(

        <DropDownListContainer>
        <DropDownList>
          {data.map( (option, index) => (
            <ListItem onClick={onOptionClickedInADropdown(option, subject)} key={index}>
              {option}
            </ListItem>
          ))}
        </DropDownList>
      </DropDownListContainer>


      )


    } // end WHEN EXPANDED

   

   // THESE TOGGLES WILL BE PASSED INTO onClick ... 
  // set up a function that will be called not immediately but passed into onClick
  // No args TWO EQUALS - const toggleOpenCloseDrop = () => {
  // if arg THREE EQUALS
  // INLINE is onClick = { () => functionName() } with paretheses
  // 
  const onClickDropdownHeadertoggleOpenCloseDrop = subject => () => {
    console.log("onClickDropdownHeadertoggleOpenCloseDrop - Should be triggered when dropdown header is clicked.");
    //console.log("toggleOpenCloseDrop - TOGGLE ISOPEN");

    console.log(`Check what the subject of the Dropdown is: ${subject}`);
    console.log(convertObjectToArrayOfValues(subject));
    console.log(convertObjectToArrayOfKeys(subject));

    console.log(Object.values(subject)[0]); // not sure whey we have t o do

    console.log(`state isOpenDistricts is currently: ${isOpenDistricts} where false means it was closed`);
    console.log(`state isOpenCities is currently: ${isOpenCities} where false means it was closed`);
    

    if(Object.values(subject)[0]==="District") {
      
      console.log(`Change the state isOpenDistricts to opposite. New value will be :: ${!isOpenDistricts}`);
  

      console.log("About to call setIsOpenDistricts which will change value of STATE isOpenDistricts");
      console.log("Note that state isOpenDistricts is used inside <Dropdown so state chagne will rerender it")
    
      // all this determines is whether dropdown open or closed. 
      
      setIsOpenDistricts(!isOpenDistricts);

    } else if (Object.values(subject)[0]==="CityTown") {
       console.log(`Change the state isOpenCities to opposite. New value will be : ${!isOpenCities}`);
  

    console.log("About to call setIsOpenCities which will change value of STATE isOpenCities");
    console.log("Note that state isOpenCities is used inside <Dropdown so state chagne will RE-render it")
    
      // all this determines is whether dropdown open or closed. 
      
      setIsOpenCities(!isOpenCities);
    } else {

      console.log("NOT the districts, NOR the cities dropdown clicked");
    }
    
    
  }

    // = subject => () => {
  //  const handleViewOneFountainItem = id => () => {
    const handleViewOneFountainItem = fountainId => () => {
      // function handleViewOneFountainItem(fountainId) {
        console.log(`\nhandleViewOneFountainItem!`);
        console.log("User clicked handleViewOneFountainItem!");
        console.log(fountainId.item); // for some reason comes as an object with key item
        
        setIsSingleFountainView(true);

        setSingleFountainId(fountainId.item);
        
        checkAuthThenFetchOneFountain(fountainId.item);
    
      }


      const handleEditTextArea = fountainId => () => {
            console.log("handleEditTextArea");
      }

         // = subject => () => {
  //  const handleViewOneFountainItem = id => () => {
    const handleEditOneFountainItem = fountainId => () => {
      // function handleViewOneFountainItem(fountainId) {
        console.log("User clicked handleEditOneFountainItem!");
        console.log(fountainId);
        console.log(fountainId.item); // for some reason comes as an object with key item
        
        setIsSingleFountainView(true);

        setSingleFountainId(fountainId.item);
        
        checkAuthThenFetchOneFountain(fountainId.item);
    
      }

      // beats me where event comes from - not even passed in! 
      async function handleSaveEditOneFountainItem(event) {
        // function handleViewOneFountainItem(fountainId) {
          console.log("User clicked handleSaveEditOneFountainItem!");
          //console.log(fountainId.item); // for some reason comes as an object with key item
          setIsEditView(false);
            //TO DO CALL API TO UPDATE 
            event.preventDefault(); // stops Form submission canceled because the form is not connected
          const mutation2use = updatePersonalFountainMutation;
          
          event.preventDefault();
          const form = new FormData(event.target);

          const districtRaw = form.get("District").toLowerCase();
          const districtFirstLetterCaps = districtRaw.charAt(0).toUpperCase() + districtRaw.slice(1)
      
          const cityRaw = form.get("CityTown").toLowerCase();
          const cityFirstLetterCaps = cityRaw.charAt(0).toUpperCase() + cityRaw.slice(1)

          console.log(singleFountainId);
          console.log(cityFirstLetterCaps);

           // used in input: data ...
          const inputData = {
              id: singleFountainId,
              Location: form.get("Location"),
              CityTown: cityFirstLetterCaps,
              District: districtFirstLetterCaps,
              Type: "PersonalFountain",
              Verified: "y"
          };

          console.log(`inputData about to be passed into query 
          ${mutation2use} as input is: `);
          console.log(inputData);


          await API.graphql({ 
            query: mutation2use,
            authMode:"AMAZON_COGNITO_USER_POOLS",
            variables: { input: inputData },
          })
        .then( (apiDataResp) => { 
          console.log(".THEN( !");


          console.log("Edit Saved OK! ");
          
          console.log(apiDataResp);

          //alert("Fountain Added");

        }
          ).catch(


            // possible errors "Variable 'input' has coerced Null
            error => {console.log("There has been an error in query createFountainMutation");
            console.log(error)
            // alert("There has been a problem adding the fountain")
            alert(`There has been a problem adding the fountain 
            \n\n${error.errors[0].message} \n\n ${mutation2use}`)
          }

            
          )


          event.target.reset(); // without this you get a messge reset log.
      
        }


    // DEFINE HOW 2 RESPOND TO CLICK OF AN ITEM IN A DROPDOWN ...
    // onChange ...
    const onOptionClickedInADropdown = (value, subject) => () => {
      console.log("ON OPTION CLICKED");
      console.log("onOptionClickedInADropdown");
    
      console.log("selectedTagOption state value BEFORE set called with new value is: ");
      console.log(selectedTagOption);

      console.log("subject is ");
      console.log(subject);
  
      console.log("The value clicked on was: ");
      console.log(value);

      // START AFRESH - BLANK STUFF TO AVOID wrong token being used with wrong query.
      setNextToken(null); // dont worry wont cause immediate re-render. remember it stores them up!
      // TWO reasons we used clumsy nextneft (1) JS convention to extract key name clashes with state (2) nwxtT currently in useEffect (nextNext is NOT dependency) 
      setNextNextToken(null); // NN used in onClick. Safer as not a dependency of UE.

      if(subject==="District"){
        console.log("VIP -The subject of the dropdown that was clicked is districts so change states relating to it only ")
        // LOGIC - states for different dropdowns should be different
        console.log(`CURRENT selectedDistrict is ${selectedDistrict}`);
        console.log(`CALL setSelectedDistrict( passing in ${value} `);
       
        //setSelectedTagOption(value); // causes state to be updated.
        

        // APPROACH ONE - change district and then useEffect with dependency triggered.
        // 
        setSelectedDistrict(value);
        
        console.log("CLOSE relevant dropdown once user has clicked choice, pass FALSE INTO setIsOpenTopics");
        setIsOpenDistricts(false); // close it after user clicked an option!  
        
        console.log("Now that we've updated the dropdown, fetch data by district ");
        

        fetchPersonalFountainsThenSetFountains("District", value);

      } else if (subject==="CityTown"){
        console.log("VIP -The subject of the dropdown that was clicked is cities so change states relating to it only ")
        // LOGIC - states for different dropdowns should be different
        console.log(`CURRENT selectedCity is ${selectedCity}`);
        console.log(`BIG MOMENT! CALL setSelectedCity( passing in ${value} `);
        //console.log("ITS BIG cos selectedCity  is DEPENDENCY for 2nd UseEffect");
        //setSelectedTagOption(value); // causes state to be updated.
        
        setSelectedDistrict(null);
        setSelectedCity(value); // no longer a useEffect dependency.
        console.log("CLOSE relevant dropdown once user has clicked choice, pass FALSE INTO setIsOpenX");
        setIsOpenCities(false); // close it after user clicked an option!  
        
        console.log("Now that we've updated the dropdown, fetch data by CityTown ");
       
        fetchPersonalFountainsThenSetFountains("CityTown", value);

      } else {

        console.log("I don't know which dropdown had an option clicked in!")
      }
      
     
    }; // end clicked topic 

  
 //console.log("isMobile: ");
 // console.log(isMobile);
 // console.log("md_at_least_800: ");
 // console.log(md_at_least_800);


  var tableHeaders = ['Id','Site', 'Name', 'District', 'Town/City', 'Description', 'Action' ];
  // array of arrays! 
  const tableRows = [
    ['John Doe', 30, 'USA'],
    ['Jane Smith', 25, 'Canada'],
    ['Alice Johnson', 28, 'UK'],
  ];

  // whereas fountains element in array is
  // {id: 'a07bd066-11fe-46d6-856c-77438d7fcda7', name: 'Rosendale Road Lambeth', site: 'Rosendale Road Lambeth', district: 'Lambeth', city: 'London',

  const styleEquals_4th_paddingLeft = {
    paddingRight: "50px",
    textAlign : "left"
  };

  const styleEquals_Hidden = {
    display: "none"
  };

  const styleEquals_4td_paddingLeft = {
    paddingRight: "50px",
    textAlign : "left"
  };

   // For MOBILES ...
  // MAKE col BEHAVE like a ROW (for mobiles!)
  const styleEquals_4td_behaving_like_row = {
    display: "block",
    textAlign : "left"
  };

  const styleEquals_4td_mobile_label = {
    width: "170px",
    paddingRight: "30px",
    textAlign : "left"
  };

  const fakeData = {
    title: "The Promise",
    blurb: "Lorem Ipsum",
    cover_image: "Lorem Ipsum",
    pub_year: 2002,
    genre: "Foobar"
  };

  // THIS FUNCTION takes an object {key1: v1, key2: v2} and outputs [v1, v2]
  function convertObjectToArrayOfValues(oneObject) {
    let result = [];
    Object.values(oneObject).forEach((v, i) => {
      result[i] = v;
    });
    return result;
  };

  // THIS FUNCTION takes an object {key1: v1, key2: v2} and outputs [v1, v2]
  function convertObjectToArrayOfKeys(oneObject) {
    let result = [];
    Object.values(oneObject).forEach((v, i) => {
      result[i] = v;
    });
    return result;
  };
 

  // useEffect seems to be stuff you call for FIRST RENDER
  // example, go fetch data you need to show! 
  // 2nd parameter of useEffect often seems to be empty array - if empty means only call after 1st render.
  // if non-empty array it means if ANYTHING in the array of dependencies changes then call again! 
  //      useEffect(() => {
  //        console.log("U U U U U U U U U ");
  //        
  //        console.log("THIS IS useEffect - called 1st time AND if change to dependency state (e.g. [nextToken]");
 //         console.log("From useEffect - Call fetchPersonalFountains!");
 //         console.log("this useEffect has dependency on nextToken state!");
  //        fetchPersonalFountainsThenSetFountains();
   //     }, [nextToken]); // you may want to call fetchPersonalFountains inside this useEffect when state nextToken changes when user clicks next or prev

  // if district filter changes this runs ...
  // if selectedCity changes this runs!
  // when selectedCity changes, disctrict goes to null! 
  useEffect(() => {
    console.log("U U U U U U U U U ");
    // called FIRST TIME and if nextToken changes!
    console.log("THIS IS useEffect - with DEPENDENCY on nextToken");
    console.log("From useEffect - Call fetchPersonalFountainsThenSetFountains !");

    if(selectedDistrict) {
      console.log("Call setNextToken triggers BOTH RE-RENDER (due to state change) and  calls useEffect code (cos its listed as useEff dependency)!");
    
      fetchPersonalFountainsThenSetFountains("District",selectedDistrict); 

    } else {
      console.log("fetchPersonalFountainsThenSetFountains('CityTown,''London')");
     // fetchPersonalFountainsThenSetFountains("CityTown","London"); //TODO - update method
 
      fetchPersonalFountainsThenSetFountains("CityTown","London"); 
    }

     }, [nextToken]); // you may want to call fetchPersonalFountains inside this useEffect when state nextToken changes when user clicks next or prev


    // if district filter changes this runs ...
    //      useEffect(() => {
    //        console.log("U U U U U U U U U ");
          
    //        console.log("THIS IS useEffect - with DEPENDENCY on filterByCITY state");
    //        console.log("From useEffect - Call fetchPersonalFountainsThenSetFountains !");
    //        console.log(`Pass filterType as arg --> ${filterByCity}`);
    //        console.log("this useEffect has dependency on filterByCity state!");
    //        fetchPersonalFountainsThenSetFountains("city",filterByCity); //TODO - update method
    //      }, [filterByCity]); // you may want to call fetchPersonalFountains inside this useEffect when state nextToken changes when user clicks next or prev




  // THIS IS KEY ... note it is called from inside useEffect!
  async function fetchPersonalFountainsThenSetFountains(argKey, argValue) {
    console.log("THIS is fetchPersonalFountainsThenSetFountains ... lets call the graphQL API passing in query:")

    console.log(`argKey if any is: ${argKey}`);
    console.log(`argValue if any is: ${argValue}`);

    // SET UP most common variables type:

     // GOOD OLD VARIABLES ... 
    // e.g. variables: { filter: { isEnabled: { eq: true } }, limit: 10000 },
    // Filter Expression can only contain non-primary key attributes: Primary key attribute: district (Service: DynamoDb, Status Code: 400, Request ID:
    // Above error - if you try to both FILTER and SORT by same thing - makes no sense! 
    // Your timestamp field is the sort key of the table, so it cannot be used in FilterExpression. It must be part of the KeyConditionExpression.
    // verified is a HASH (cos its got @index against it)
    // FAKE FIELDS verified and type
    // LOWER LIMIT WHEN TROUBLE SHOOTING TO 
    // BELOW IS DEFAULT (no city or district filter ...)
    var variables = {
      limit: 6, 
      Verified: "y",
      //filter: { district: { eq: "Islington" } }
      //filter: { city: { eq: "London" } }
      // add filter as needed
    };

   
    
    // logic - DEFAULT Is to sort by district but cannot if also filtering by district.
    var query2use = fountainsSortedByDistrictLimitedAttributes;

    // if districtHasJustChanged. (when city changed make this back to false)
    // really should be argumentKey and argumentValue
    if(argKey === "District" && argValue){
    //if(districtHasJustChanged && selectedDistrict){
      console.log("argKey is District  ")
      console.log(`There IS a argValue - it's ${argValue} `)
      
      console.log("USE LimitedAttributes version passing in argValue as an INPUT argument ")
      // ironically if FILTERING by district you do NOT SORT by district!
      //query2use = listFountainsLimitedHeaders
      query2use =  fountainsSortedByDistrictLimitedAttributes

      var eqValue = { eq: argValue} 
      //var districtFilter = { District: districtEqualsFilter }
      // e.g. District: {eq: "Barking and Dagenham"}
      

      // low limit when trouble shooting! Reduces overloggin!
      // DO NOT USE FILTERS - use DISTRICT AS ARGUMENT
      //  fountainsSortedByDistrict(Verified: "y", District: {eq: "Barking and Dagenham"}) {
      variables = {
        Verified: "y",
        District: eqValue,
        limit: 20
        //filter: districtFilter
        // add filter as needed
      };

    } else if (argKey === "CityTown" && argValue){
     
      console.log(`argKey is ${argKey}`);
      //var cityEqValue = { eq: filterValue} 

      var eqCityValue = { eq: argValue} 
      //var cityFilter = { CityTown: cityEqualsFilter }


      // if FILTERING by anything other than district then SORT by district.
      //query2use = fountainsSortedByDistrictLimitedAttributes;
      query2use = fountainsSortedByCityTownLimitedAttributes

      variables = {
        Type: "PersonalFountain",
        CityTown: eqCityValue,
        limit: 20
        //filter: cityFilter
        // add filter as needed
      };


    } else {
      console.log("filterKey is NOT District OR NULL filterVALUE so OK to SORT by district ")
      // see default.
    }

    console.log("Q Q Q Q Q Q Q Q Q Q Q Q Q ");
    console.log("query2use is: ");
    console.log(query2use);
    //const apiData = await API.graphql({ query: listPersonalFountains })
    // TO ALLOW UNAUTHENTICATED USERS to access add authMode: AWS_IAM which 
    // AppSync console will say forbidden for any IAM unless u follow these steps:
    // https://docs.amplify.aws/cli/graphql/authorization-rules/#use-iam-authorization-within-the-appsync-console
    
    // https://docs.amplify.aws/lib/graphqlapi/query-data/q/platform/js/#paginating-queries
    
     // used in MUTATION input: data ...
     const data = {
      Verified: "y"
    };
    
    // If filterType === undefined then query2use = fountainsSortedByDistrictLimitedAttributes
    console.log("Lets check if STATE nextToken has a value from currently visible API response");
    console.log(nextToken);

    if(nextToken) {
      console.log("There IS a defined value for nextToken state so NOT last page!");
      console.log("Include a nextToken key and value in variables passed into upcoming API call");
      variables.nextToken = nextToken;

    } else {
      console.log("There is NO defined value for nextToken state so 1st or last!");
      console.log("This means we pass a LIMIT into QUERY but no nextToken key!");
    }

    console.log("query VARIABLES: ")
    console.log(variables);


    // check authenticated then call api ...
    console.log("Call ACAU Auth.currentAuthenticatedUser() to check if user logged in or not")
    console.log("EXPECT DELAY WHILE AUTH API RESPONDS ...(look4 wait is over...)")
  
    try {
      await Auth.currentAuthenticatedUser();
      console.log(`\n.. Auth WAIT IS OVER`);
      console.log("... A user is logged on! Use Cog User Pool authMode");
      //return true;

      console.log("\nCall hideEditDeleteButtonsIfUnathenticated(false) ");
      
     

      console.log("\nFetch. Now that auth sorted, BIG MOMENT- call graphQl with query, variables and authMode !! ");
     

      console.log("F F F F F F F F F F F F F F F F  ");
      console.log("Fetch -- DELAY DISTINCTLY POSSIBLE ... ");
     
      //graphqlOPERATIONS approach
      //const result = await API.graphql(
      //  graphqlOperation(listProviders, {filter, limit})
      //);

      //const variables = {
      //filter: {
          //priority: {
            //eq: 1
          //}
        //}
      //};

      // REMEMBER TO DUPLICATE for UNAUTHENTICATED below ...
      // QVA ...
      await API.graphql({ 
        query: query2use,
        variables: variables,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        })
      .then( (apiDataResp) => { 
        console.log(".THEN( !");

        console.log("OK API!");
     
        console.log("FETCH SUCCESS - FETCH SUCCESS - FETCH SUCCESS ");
     


        console.log("A A A A A Fetch AUTHENTICATED VERSION A A A A A A A ");
        
        console.log("FINALEMENT!!!! ")
        console.log("apiDataResp is:")
        console.log(apiDataResp);

        console.log(`query2use was ${query2use}`);

        console.log(`** ${selectedCity} ** was selectedCity`);
        console.log(`variables.filter was ${variables.filter}`);
        

      

         // don't TRY TO PUT TOO MUCH in TRY block beause if sth fails inside it and catch is called you wont know which part you tried failed! 
        setHideEditDeleteButtonsIfUnathenticated(false);

        nowThatWeHaveTheDataUpdateUx(apiDataResp);

        }
        ).catch(
          error => {
            // catch 4 await API.g
            console.log(`\n.. WAIT IS OVER`);
            console.log("AA - UH OH! - Fetch");
            console.log("There has been an error in fountainsSortedByDistrictLimitedAttributes user logged in");
            console.log(error);
            console.log(Object.keys(error));
            
            console.log(variables);
            console.log(query2use);
            console.log(error.errors[0].message);
            alert(`AA Error - might be no internet or failed query! Try Query in AppSync console\n OPTION COMM I\n\n${error.errors[0].message}\n\n ${query2use}`);
           }
        )
  } catch {
    // catch4 Auth.  
    //return false for ;Auth.currentAuthenticatedUser() 
      console.log(`\n.. WAIT IS OVER`);
      console.log("Auth Catch - User possibly NOT logged in. User IAM as provider / authMode! in API call");
      // dont show edit or delete if not logged in.
      setHideEditDeleteButtonsIfUnathenticated(true);
      console.log("GRAN MOMENTO - call graphQl with query, variables and authMode = AWS_IAM ! ");
      console.log(`NA NA -  API.graphql QVA: ${query2use}`);
      console.log(` API.graphql QVA:- EXPECT A WAIT ...`);
      
      await API.graphql({ 
        query: query2use,
        variables: variables,
        authMode: 'AWS_IAM',
        })
      .then( (apiDataResp) => { 
        console.log(".THEN( success!");
        console.log("THE WAIT IS OVAH! - IAM - Guest Access");
        console.log(" NA NA NA - user NOT Logged in - A A A - user Not Logged in");
        
        console.log("apiDataResp is:")
        console.log(apiDataResp)
        nowThatWeHaveTheDataUpdateUx(apiDataResp)}

        ).catch(
          error => {console.log(`\nWiat over - NA - UH OH!`);

          console.log(`\nIAM API CALL ERROR`);
          console.log(`There has been an error in query ${query2use} user not logged in`);
          console.log(error);
          console.log(Object.keys(error));
          console.log(error.errors[0]); // The 401 (Unauthorized) status code indicates that the request has not been applied because it lacks valid authentication credentials for the target resource
          //console.log(error.errors);
          console.log(`\nThis below will tell graphql issues:`)
          console.log(`MESSAGE: ${error.errors[0].message}`);
          alert(`NA NA Error - SEE MESSAGE in logs. Might be no internet or failed query! Try Query in AppSync console.\n OPTION COMM I \n\n ${query2use} \n\n ${variables} \n\n See Q Q Q Q Q for original query setup and filters!`);
          // eg., Variable 'verified' has coerced Null (means you forgot to specify value for verified in inputs)
          // Filter Expression can only contain non-primary key attributes: Primary key attribute: district (Service: DynamoDb,
          // ABOVE error happens if you try to filter AND sort by the same sort key field e.g. district.
          // Validation error of type UnknownType: Unknown type ModelPersonalFountainFilterInput
        }
        )
    
    } // end try catch for authenticated user.
    
   
   
  } // end fetchFOuntains



  // THIS IS KEY ... note it is called from inside useEffect!
  async function checkAuthThenFetchOneFountain(fountainId) {
    console.log("THIS is checkAuthThenFetchOneFountain:")

    console.log(`fountainId if any is: ${fountainId}`);

    //console.log(Object.keys(fountainId));
 
    console.log("Call ACAU Auth.currentAuthenticatedUser() to check if user logged in or not")
    console.log("THERE MAY BE A DELAY ... ")
   
    try {
      await Auth.currentAuthenticatedUser();
      console.log("A user is logged on! Use Cog User Pool authMode");
      
      const authMode = "AMAZON_COGNITO_USER_POOLS";
      fetchOneFountainThenSetFountains(fountainId,authMode);

  } catch {
      //return false for ;Auth.currentAuthenticatedUser() 
      console.log("User NOT logged in. User IAM as provider / authMode! in API call");
   
      const authMode = 'AWS_IAM';
       //  authMode: 'AWS_IAM',

       fetchOneFountainThenSetFountains(fountainId,authMode);
    
        } // end catch
    
      } // end fetchFOuntains
   

async function fetchOneFountainThenSetFountains(fountainId,authMode) {

  console.log("THIS is fetchOneFountainThenSetFountains");
  console.log(`THIS is fetchOneFountainThenSetFountains for fountainID ${fountainId}`);
  console.log(fountainId);

  console.log("LETS decide on our QUERY name and VARIABLES for our API call!");

        const query2use = getDersonalFountain;

        const variables = {
          id: fountainId
          //filter: cityFilter
          // add filter as needed
        };

        console.log("Q Q Q Q Q Q Q Q Q Q Q Q Q ");
        console.log("query2use is: ");
        console.log(query2use);

        console.log("variables is: ");
        console.log(variables);
        //const apiData = await API.graphql({ query: listPersonalFountains })
        // TO ALLOW UNAUTHENTICATED USERS to access add authMode: AWS_IAM which 
        // AppSync console will say forbidden for any IAM unless u follow these steps:
        // https://docs.amplify.aws/cli/graphql/authorization-rules/#use-iam-authorization-within-the-appsync-console

        // https://docs.amplify.aws/lib/graphqlapi/query-data/q/platform/js/#paginating-queries

        // used in input: data (MUTATIONS)...
        const data = {
        verified: "y"
        };

        // QVA ...
      await API.graphql({ 
        query: query2use,
        variables: variables,
        authMode: authMode,
        })
      .then( (apiDataResp) => { 
        console.log(".THEN( !");

        console.log("apiDataResp is:")
        console.log(apiDataResp)

        console.log("apiDataResp.data is:")
        console.log(apiDataResp.data)
        

        nowThatWeHaveOneFountainUpdateUx(apiDataResp);

      }
        ).catch(
          error => {
            console.log("UH OH!");
            console.log("There has been an error in getPersonalFountain ");
            console.log(error)
            console.log(`Object.keys(error) is ${Object.keys(error)}`);
            // Example error: if you change DB table field name in backend but front-end middleware still refers to old field name (e.g. ycoordinate)
            // Validation error of type FieldUndefined: Field 'ycoordinate' in type
            // Check file queries and check if you have $Verified its $Verified elsewhere.
            // Validation error of type UnusedVariable: Unused variable Verified
            // If ur queries contain attribs (e.g. Site) that DO NOT EXIST in db, you will get:
            // Validation error of type FieldUndefined: Field 'Si…ndefined @ 'fountainsSortedByDistrict/items/Site
            alert(`Error - fetchOneFountainThenSetFountains - might be no internet or failed query! Try Query in AppSync console\n OPTION COMM I\n\n ${query2use}`);
           }
        )




}






const handleSearch = async (e) => {
  e.preventDefault();

  console.log("latest value of searchTerm is:");
  console.log(searchTerm);

  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  try {
    await Auth.currentAuthenticatedUser();
    console.log(`\nSuccessful currentAuthenticatedUser - implies logged into a Cognito User Pool!`);
    console.log("handleSearch:FINALLY - After a DELAY ... ");
    console.log("handleSearch: A user is logged on! Use Cog User Pool authMode");
    console.log("\nhandleSearch: BIG MOMENT- call graphQl with query, variables and authMode !! ");
    //return true;

            // now try to do a search using authMode COG
            try {

              // v5 -- await API.graphql<GraphQLQuery<CreateTodoMutation>>({
                // v6 - await client.graphql({ query: createTodo, variables: {input: todo}});
              //const data = await API.graphql(graphqlOperation(searchByStreet, { street: searchTerm }));
              // const data = await client.graphql(graphqlOperation(byBotoHashAndLocationSortKey, { street: searchTerm }));

              const apiResp = await API.graphql({
                query: byBotoHashAndStreetNameSortKey,
                variables: {
                  Boto: "y", // Ensuring this is not null or undefined
                  StreetName: {
                    beginsWith: lowerCaseSearchTerm // Adjusting this to match the expected input type structure
                  }, // Assuming searchTerm is defined correctly
                  // Include other necessary variables such as sortDirection, filter, limit, and nextToken as needed
                }
              });

              console.log("The apiResp returned by client.graphql(v5) or client.graphql (v6) is: ");
              console.log(apiResp);
              console.log(apiResp.data);
              console.log(apiResp.data.byBotoHashAndStreetNameSortKey);

              nowThatWeHaveTheDataUpdateUx(apiResp);


              //setResults(data.data.byBotoHashAndStreetNameSortKey.items);
            } catch (err) {
              console.error("Error fetching data: ", err);
            }
  

    

   
} catch {
    //return false for ;Auth.currentAuthenticatedUser() 
    console.log(`\nUser NOT logged in 2 Cognito User Pool. User IAM as provider / authMode! in API call`);
    
             // now try to do a search using authMode COG
             try {

              // v5 -- await API.graphql<GraphQLQuery<CreateTodoMutation>>({
                // v6 - await client.graphql({ query: createTodo, variables: {input: todo}});
              //const data = await API.graphql(graphqlOperation(searchByStreet, { street: searchTerm }));
              // const data = await client.graphql(graphqlOperation(byBotoHashAndLocationSortKey, { street: searchTerm }));

              const apiResp = await API.graphql({
                authMode: "AWS_IAM",
                query: byBotoHashAndStreetNameSortKey,
                variables: {
                  Boto: "y", // Ensuring this is not null or undefined
                  StreetName: {
                    beginsWith: lowerCaseSearchTerm // Adjusting this to match the expected input type structure
                  }, // Assuming searchTerm is defined correctly
                  // Include other necessary variables such as sortDirection, filter, limit, and nextToken as needed
                }
              });

              console.log("The data returned by client.graphql(v5) or client.graphql (v6) is: ");
              console.log(apiResp);
              console.log(apiResp.data);
              console.log(apiResp.data.byBotoHashAndStreetNameSortKey);

              nowThatWeHaveTheDataUpdateUx(apiResp);


              //setResults(data.data.byBotoHashAndStreetNameSortKey.items);
            } catch (err) {
              console.error("Error fetching data: ", err);
            }
  

    
        
  
    } // end try catch for Cognito authenticated user.


 

};

    // defunct
    async function fetchNotes() {
      console.log("THIS is OBSOLETE fetchNotes ... lets call the graphQL API passing in query:")
      //const apiData = await API.graphql({ query: listPersonalFountains }); // no mention of graphqloperations! 
      // STRIP OUT OUTER KEYS of data: and items:
      //const notesFromAPI = apiData.data.listPersonalFountains.items;
      //console.log("Queue up an Update to the UI by calling setSomeState ...");
      //setNotes(notesFromAPI);
    }


  function nowThatWeHaveTheDataUpdateUx(apiDataResp) {
    // the bit after data. driven by schema.graphql:  queryField: "fountainsSortedByDistrict"

    console.log("\nTHIS IS nowThatWeHaveTheDataUpdateUx")
    console.log("KEYS of the apiDataResp.data are (should be same as QUERY NAME): ")
    console.log(Object.keys(apiDataResp.data));
    const dataKey = Object.keys(apiDataResp.data);

    console.log("data after extracting outerkey:")
    
    console.log(apiDataResp.data[dataKey]);

    var itemsAndNextToken = apiDataResp.data[dataKey];
    var items = itemsAndNextToken.items; // SINGLE FOUNTAIN RESPONSE DOES
    var nextTokenFromApi = itemsAndNextToken.nextToken;

    console.log("items")
    console.log(items);
   

    if (items.length == 0 ) {
      alert("There are no fountains matching that search");
    } else {
      console.log("There ARE ");
      console.log(items);
      // below items and nextToken must match keys from api response
      const { items: fountainsFromAPIpage1, nextToken } = apiDataResp.data[dataKey];
      //const fountainsFromAPI = apiData.data.listPersonalFountains.items;
      
      
      console.log("nextTokenFromApi is");
      console.log(nextTokenFromApi);
      console.log("nextToken (from API) (non-null implies next page as LIMIT exceeded on this page): ");
      console.log(nextToken); // SCOPE 4 CONFUSION - this is not the state but the nextToken from api resp.
  
      console.log("SAVE the nextToken in state by Calling setNextNextToken");
      // Fetch the next 20 records
      setNextNextToken(nextToken); // this sets things up so when user clicks next this gets used in the next API call!
      
      console.log("nextToken stuff will NOT be used until next API call. For NOW, render fountains from latest API call");
     

      //console.log("Call setNextNextToken");
      // Fetch the next 20 records
      //setNextNextToken(nextToken); // 

      // to get headers you can just look at first fountain and extract its headers.
      const tableHeadersFromApi = Object.keys(fountainsFromAPIpage1[0]);
      setHeaders(tableHeadersFromApi);
     
      console.log("Call setFountainsToDisplay (AKA value of fountains changes so update UX ");
      // VIP this updates the UX!!! 
      setApiListFountains(fountainsFromAPIpage1);

      // if you wanted to add an image to every one in the list ..
     // fountainsFromAPIpage1.map(async (fount) => {
     //   if (fount.image) {
     //     const url = await Storage.get(fount.name);
     //     fount.I = url;
     //   }
     //   return note;
     // })


      // old
      //setPersonalFountains(fountainsFromAPIpage1);
      // new
      setFountainsToDisplay(fountainsFromAPIpage1);

    }

   
  } // end of nowThatWeHaveTheDataUpdateUx


  async function nowThatWeHaveOneFountainUpdateUx(apiDataResp) {
    // the bit after data. driven by schema.graphql:  queryField: "fountainsSortedByDistrict"

    console.log("\nTHIS IS nowThatWeHaveOneFountainUpdateUx")
    console.log("KEYS of the apiDataResp.data are (should be same as QUERY NAME): ")
    console.log(Object.keys(apiDataResp.data));
    const dataKey = Object.keys(apiDataResp.data);

    console.log("data after extracting outerkey:")
    
    console.log(apiDataResp.data[dataKey]);

    var oneFountain = apiDataResp.data[dataKey];
    //var items = itemsAndNextToken.items; // SINGLE FOUNTAIN RESPONSE DOES NOTE HAVE ITEMS KEY



    // strap on a new key to the oneFountain object
    // add the full image URL ...
    if (oneFountain.ImageUrl) {
      const fullImageUrl = await Storage.get(oneFountain.ImageUrl);
      console.log("There IS an image and fullImageUrl is:");
      console.log(fullImageUrl);
      // add new key
      oneFountain.fullImageUrl = fullImageUrl;
    } else {
      oneFountain.fullImageUrl = "";
    }
   
    // use the keys as the col row headers!
      const tableHeadersFromApi = Object.keys(oneFountain);



// also strap  on a header ..

      setHeaders(tableHeadersFromApi);
     
      console.log("Call setFountains (AKA value of fountains changes so update UX ");
      // VIP this updates the UX!!! 

      // put the ONE FOUNTAIN into an array so because that's what setPersonalFountains expects! 
      var fountainsArray = [];
      fountainsArray.push(oneFountain);

      setSingleFountainObject(oneFountain);

      setSingleFountainFullImageUrl(oneFountain.fullImageUrl);

      // an array containing ONE fountain!
      //setPersonalFountains(fountainsArray);
      // new - an array containing ONE fountain!
      setFountainsToDisplay(fountainsArray);

    

   
  }



  function onClickNextPage() {
    console.log("User clicked next page!");
    console.log("The API call that powered CURRENT page returned a nextToken with a (nextNextToken STATE) value of..");
    // we use nextNextToken because no useEffect dependency
    console.log(nextNextToken);

    if(nextNextToken === null) 
    {
      console.log("Next page token (nextNextToken) NULL")
    } else if (nextNextToken === undefined) {
      console.log("nextNextToken UNDEFINED Next page token")
    } else {
      console.log("NON-NULL nextNextToken token")
    }
    //console.log("NOW call setNextToken( which will trigger TWO THINGS!");
    //console.log("Call setNextToken triggers BOTH RE-RENDER (due to state change) and  calls useEffect code (cos its listed as useEff dependency)!");
    
    console.log("Lets now re-call the relevant API");

    
    
    //setPreviousTokens((prev) => [...prev, nextToken])
    // lOGIC - when you did API call you got a token for the next page and you saved it via setNextNextToken
    // what got returned by api call that made CURRENT page will be used in next api call!
    setNextToken(nextNextToken) // THIS WILL TRIGGER running of useEffect code due to useEffect dependency [nextToken]!!! 
    // whats confusing about above is you pass in nextNextToken but the func is setNextToken.
    setNextNextToken(null) // reset value to null (will be updated again) .. it does NOT cause 

  }



  async function createPersonalFountain(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    const data = {
      name: form.get("name"),
      description: form.get("description"),
      site: form.get("site")
    };
    await API.graphql({
      query: createPersonalFountainMutation,
      variables: { input: data },
    });
    fetchNotes();
    event.target.reset();
  }

  // behaviour different if curlies ... ({})
  async function deletePersonalFountain( id ) {

    console.log("this is deletePersonalFountain")
    console.log(id);
    console.log({id});
    //const newPersonalFountains = fountains.filter((fountain) => fountain.id !== id);
    //setPersonalFountains(newPersonalFountains);
    await API.graphql({
      query: deletePersonalFountainMutation,
      variables: { input: { id } },
      authMode: "AMAZON_COGNITO_USER_POOLS"
    })
    .then( (apiDataResp) => { 
      console.log(".THEN( !");


      console.log("A A A A A Delete AUTHENTICATED VERSION A A A A A A A ");
      
      console.log("apiDataResp is:")
      console.log(apiDataResp)
      
      setIsSingleFountainView(false);
      fetchPersonalFountainsThenSetFountains();

      //nowThatWeHaveTheDataUpdateUx(apiDataResp);

    }
      ).catch(
        error => {
          console.log("AA - UH OH!");
          console.log("There has been an error in deletePersonalFountainMutation user logged in");
          console.log(error);
          console.log(Object.keys(error));
          
          //console.log(variables);
          //console.log(query2use);
          console.log(error.errors[0].message);
          alert(`AA Error - might be no internet or failed query! Try Query in AppSync console\n OPTION COMM I
          \n\n${error.errors[0].message}\n\n `);
         }
      );
  }

    // HOW PROMISES WORK ... 
    function resolveAfter2Seconds(x) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(x);
        }, 2000);
      });
    }
    
    async function waitFor2Secs() {
      const x = await resolveAfter2Seconds(10);
      console.log("RESOLUTION WAS ..."); 
      console.log(x); // 10
    }

    const EditFountain = ( {headers, rows, isMobile}) => {


      console.log("this is EditFountain");
      console.log(rows);

      console.log(singleFountainId);
      console.log(singleFountainObject);

      return (
        // Its the FORM View that has onSubmit ! 
        <View as="form" margin="3rem 0" onSubmit={handleSaveEditOneFountainItem}>
 
        <Flex direction="column" justifyContent="center">


                      {/* The name field used to access info (e.g. .Location) VIA form.get("Location")  */}
                    <TextField
                        name="Location"
                        defaultValue = {singleFountainObject.Location}
                        label="Fountain Location"
                    
                        variation="quiet"
                        required
                      />

                    
                    <TextField
                        name="CityTown"
                        defaultValue = {singleFountainObject.CityTown}
                        label="Fountain City"
                        variation="quiet"
                        required
                      />

                    <TextField
                        name="District"
                        defaultValue = {singleFountainObject.District}
                        label="Fountain District"
                       
                        variation="quiet"
                        required
                      />


                  <TextField
                        name="GoogleMapLink"
                        defaultValue = {singleFountainObject.GoogleMapLink}
                        label="GoogleMapLink"
                       
                        variation="quiet"
                        
                      />


                <Button type="submit" variation="primary">
                  Save Changes
                </Button>
            </Flex>
    
      </View>
  
      )
    }


    // TABLE, TABLE ROW, MOBILE TABLE ROW comps defined for later use:
        const Table = ({ headers, rows, isMobile }) => {

          console.log("POC! Table component with headers, rows, isMobile passed in!");
          console.log(rows);
          console.log("POC! Table: we MAP over headers to generate <th> elements  !");
          console.log("POC! Table: we then MAP over fountains to generate rows  !");
          // You  get error TypeError: rows.map is not a function if rows is NOT AN ARRAY! 

          console.log("TABLE is it mobile?");
          console.log(isMobile)

          //console.log(fountains); // state variable 
          console.log("TABLE - fountainsToDisplay state var IS: ");
          console.log(fountainsToDisplay); // state variable 
          return (
            <table>
              <thead>
                <tr>
                {headers.map((header, index) => (
                   
                    isMobile || isSingleFountainView ?  (
                    // if mobile or single HIDE the header
                    <th key={index} style={styleEquals_Hidden}>{header}</th>)
                    : 
                    (<th key={index} style={styleEquals_4th_paddingLeft}>{header}</th>)

                ))}
                </tr>
                {/* HAVE ANOTHER ROW OF HEADERS FOR FILTERING */}
               
               
                <tr>
                      <td colSpan={headers.length}>
                      {/* create a merged column that spans number of cols that there are headers
                      AND THEN PUT A HR LINE IN IT! */}
                        <hr/>

                        </td>
                        </tr>
              </thead>
               {/* END OF TABLE HEAD THEAD */}
               
               {/* START OF TABLE BODY TBODY */}
              <tbody>
                {rows.map((row, index) => (
                  <React.Fragment key={index}>

                    <TableRow onePersonalFountainObject={row} />
                   {/* COND && <CondComp>: If condition is a truthy 
                   value, <ConditionalComponent /> is rendered 
                   NOTE - No IF or smooth brackets in the condition */}

                  {/* This is about the HORIzONTAL LINE */}
                    {index !== rows.length - 1 && <tr>
                      <td colSpan={headers.length}>
                        <hr />
                        </td>
                        </tr>}


                  </React.Fragment>
                ))}
              </tbody>
            </table>
          );
        };

      // DEFINE REACT COMPONENT:
      // TABLE ROW IS KEY ... one fountain obj is passed in!
      // See <TableRow 2 see wot data gets passed in here ...
      const TableRow = ({ onePersonalFountainObject }) => {

          //console.log(`TableROW with fountain passed in: NOW LOGGING inside React Component TableRow ${onePersonalFountainObject}`);
          //console.log(`isMobile ${isMobile}`);

          
          if(isSingleFountainView) {
            console.log(`isSingleFountainView is ${isSingleFountainView}`)
            fountId = onePersonalFountainObject.id;
            console.log(`The SingleFountain Id is ${fountId}`)
            //setSingleFountainId(fountId);
          }
          //console.log("onePersonalFountainObject is a row");

          //console.log(onePersonalFountainObject);

          //console.log(convertObjectToArrayOfValues(onePersonalFountainObject));

          let onePersonalFountainArrayOfValues = convertObjectToArrayOfValues(onePersonalFountainObject);
          //console.log("TableRow - onePersonalFountainArrayOfValues");
          //console.log(onePersonalFountainArrayOfValues);

          const fountainKeys = Object.keys(onePersonalFountainObject);
          //console.log("TableRow - fountainKeys");
          //console.log(fountainKeys);
          tableHeaders = fountainKeys;



          let localTableHeaders = {tableHeaders}

          return (
            <tr>
             
              {onePersonalFountainArrayOfValues.map((item, index) => (
                 // each 'row' contains the values of one fountain.
                 // You map over the num of values in ONE fountain in the fountainsToDisplay array.
                  // <td key={index} style={styleEquals_4td_paddingLeft}>{item}</td>
                  
                  isMobile || isSingleFountainView && !isEditView ? (  
                  <div key={index}> 
                   {/* single view labels and content */}
                    <table> 
                      <tr> <MobileTableCellLabels labelNumber={index}></MobileTableCellLabels> 

                      <MobileTableCellContent item={item} index={index}></MobileTableCellContent>
                
                  
                  </tr> </table> </div>) : ( 
                    // standard view cell content ...
                  <StandardTableCellContent item={item} index={index}></StandardTableCellContent>
                  
                  )

                  ))}
                </tr>
              );
            };

        const MobileTableCellLabels = ({ labelNumber }) => {

          //console.log("This is Comp MobileTableCellLabels ");
          //console.log(`labelNumber = index is ${labelNumber}`);
          //console.log("MobileTableCellLabels");
          //console.log(labelNumber);

          let label = tableHeaders[labelNumber];

          return(
            <td style={styleEquals_4td_mobile_label}><b>{label}</b></td>
          );

        }


        const MobileTableCellContent = ({ item, index }) => {

          if( index === 0 ) {
            //console.log("This is Comp MobileTableCellContent AND index is 0");
          } else {
            //console.log(`index is ${index} and headers.length is ${headers.length}`);
            // UNCOMMENT
            //console.log("This is Comp MobileTableCellContent ");
          }
           // IF DEVELOPING UNCOMMENT
          // console.log(`item is ${item} and index is ${index}`);
          

          //note - THERE IS A MOBILE and STANDARD version of this code! 
          if( index === headers.length - 1) {
            //console.log("index === headers.length SO MUST be LAST FIELD - make CLICKABLE!");
            //boolItemIsIdAndLastItem = true;
            if (isSingleFountainView) {
                console.log("IN SingleFountainView MODE so last item GO BACK");
                //fountId = item.id
                console.log(fountId);
                //console.log(item.id);
                
                  //item = <a onClick={back2table} href="/fountains">GO BACK</a>
                } else {
                  //console.log("WRAP last field (id) of ONE fountain in a CLICKABLE ANCHOR!");
                  //console.log("LOGIC is last FIELD per fountain SHOULD BE ID in fountains page. ITS LAST FIELD, so must be ID")
                  item = <span className="fakeBlueLink" onClick={handleViewOneFountainItem({item})}> {item} </span>
                }
              }

          //let label = tableHeaders[labelNumber];
          //   {boolItemIsIdAndLastItem  ? ({item} ) : ( {item} ) }
              

          return(
            <td style={styleEquals_4td_behaving_like_row}>
              
            {item}
              
              
              </td>
          );

        }



        const EditMobileTableCellContent = ({ item, index }) => {

          if( index === 0 ) {
            console.log("This is Comp EditMobileTableCellContent AND index is 0");
          } else {
            // UNCOMMENT
            //console.log("This is Comp MobileTableCellContent ");
          }
           // IF DEVELOPING UNCOMMENT
          // console.log(`item is ${item} and index is ${index}`);
          

          //note - THERE IS A MOBILE and STANDARD version of this code! 
          if( index === headers.length - 1) {
            //console.log("index === headers.length SO MUST be LAST FIELD - make CLICKABLE!");
            //boolItemIsIdAndLastItem = true;
            if (isSingleFountainView) {
                console.log("IN SingleFountainView MODE so last item GO BACK");
                //fountId = item.id
                console.log(fountId);
                //console.log(item.id);
                
                  item = <a onClick={back2table} href="/fountains">GO BACK</a>
                } else {
                  console.log("WRAP id in a CLICKABLE ANCHOR!");
                  console.log("LOGIC is last FIELD SHOULD BE ID in fountains page. ITS LAST FIELD, so must be ID")
                  item = <span className="fakeBlueLink" onClick={handleViewOneFountainItem({item})}> {item} </span>
                }
              }

          //let label = tableHeaders[labelNumber];
          //   {boolItemIsIdAndLastItem  ? ({item} ) : ( {item} ) }
              

          return(
            <td style={styleEquals_4td_behaving_like_row}>
              
              <textarea name="description" placeholder="Description for the task" rows="2" defaultValue="{description}" contenteditable="true" onChange={e => handleEditTextArea(e.target.value)}  />
            {item}
              
              
              </td>
          );

        }

        // typo .. standart?? 
        const StandartTableCellLabels = ({ labelNumber }) => {

          console.log("This is Comp StandartTableCellLabels ");
          //console.log(`labelNumber = index is ${labelNumber}`);
          //console.log("MobileTableCellLabels");
          //console.log(labelNumber);

          let label = tableHeaders[labelNumber];

          return(
            <td style={styleEquals_4td_paddingLeft}><b>{label}</b></td>
          );

        }


        const StandardTableCellContent = ({ item, index }) => {

          console.log("This logs first b4 EXPORT! This is Comp StandardTableCellContent ");
          //console.log(`item is ${item} and index is ${index}`);
          //console.log("MobileTableCellLabels");
          //console.log(labelNumber);

          console.log("The item in the row is: ");
          console.log(item);

         //note - THERE IS A MOBILE and STANDARD version of this code! 

          if( index === headers.length - 1) {
            console.log("index === headers.length ITS LAST FIELD -SPECIAL TREATEMENT MAKE CLICKABLE");
            console.log("WRAP id in a CLICKABLE ANCHOR!");
            //boolItemIsIdAndLastItem = true;
            console.log(`Are we in isSingleFountainView mode? ${isSingleFountainView}`)
            if (isSingleFountainView) {
              // overwrite the item
              //item = <a href="/fountains">GO BACK</a>
            } else {
              // list view
              console.log("WRAP id in a CLICKABLE ANCHOR!");
              console.log("LOGIC is last FIELD SHOULD BE ID in fountains page. ITS LAST FIELD, so must be ID")   
              item = <span className="fakeBlueLink" onClick={handleViewOneFountainItem({item})}> {item} </span>
            }
          } else {
            console.log("Not the last item in the row")
          }

          //let label = tableHeaders[labelNumber];
          //   {boolItemIsIdAndLastItem  ? ({item} ) : ( {item} ) }
              

          return(
            <td style={styleEquals_4td_paddingLeft}>
              
            {item}
              
              
              </td>
          );

        }



  // search for word FINALLY to get to this bit ...
  // FINALLY THE "RETURN" THE UX COMPONENTS STAGE .... 
  return (

    

    <View className="App height_vh" id="viewinlistfountains-excludes-nav-menu">

     {/* <Heading level={2}>Current PersonalFountains</Heading> */}


<header className="bg-gradient-poc short-please" id="fountains">
        <div className="container mt-5">
         {/*   <h1 className="yellow_bg"> PersonalFountains</h1>*/}
               </div>
       
    </header>

    <div className="whiteBackground_in_style_css">Below Header</div>

             {/* The things before the = sign is used in the child via props.searchProcessor */}
     {/*<Search />*/}

    <div>
      <h1>Drinking Fountains</h1>

      {/* Search Input and Button */}
      <form onSubmit={handleSearch}>
      <div>
        <input
          type="text"
          placeholder="Enter street name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginRight: '8px' }}
        />
        <button onClick={() => console.log(searchTerm)}>Search</button>
      </div>
      </form>
      {/* Existing content continues here... */}


      
      {  !isSingleFountainView &&
      // do not show row containing dropdowns if EDIT or single VIEW.
      // double && notation for ifs ...
      <div class="container mt-2 g-0">
            <div class="row g-0">
                <div id="verty" class="col-6">
      
               {/* Native html uses select and option elements but 
               this uses styled DIVS */}
      
     

               {/* COND && <CondComp>: If condition is a truthy 
                   value, <ConditionalComponent /> is rendered 
                   NOTE - No IF or smooth brackets in the condition - just look for closing tag */}

          {/* FIRST DROP - each drop contains DropHeader4WhenClosed and */}
              {   !isEditView &&  <Drop subject="CityTown" data={hardCities}></Drop> }  
      
     
            </div>  


            <div id="verty" class="col-6">
           {/* SECOND DROP */}
              <Drop subject="District" data={hardDistricts}></Drop>
        
            </div>  

        </div>
      </div>
      // end of CONDITIONAL RENDER FOR DROPDOWNS
      } 


     {/*    {true ?  <div> <Drop ddid="mobileonly" subject="District" data={hardDistricts}> </Drop> </div> :  <b>false</b> }  S */}


     {isSingleFountainView === true ? (
      <div>
        <div>--</div>
        {/* colorTheme and variation see https://ui.docs.amplify.aws/react/components/button
        variation="primary" colorTheme="warning"  
        primary means FILL with color! */}
        <Button colorTheme="success" variation="primary" onClick={back2table}>List Fountains</Button>
        {!isEditView && <Button  variation="primary" colorTheme="warning" onClick={click2edit}>Edit Fountain</Button>}
        {!hideEditDeleteButtonsIfUnathenticated && <Button variation="primary" colorTheme="error" onClick={click2delete}>Delete Fountain</Button>}
       
       
      </div>        ) : (
                  <div> ---- </div>
              ) 
      }

  {/*   BIG MOMENT BELOW - pass fountains into rows */}

{/*   If not edit view show the table of fountains .. */}
  { !isEditView ? (<>
  <Table headers={headers} rows={fountainsToDisplay} isMobile={isMobile} />
  <div>also this </div></>
  )
  : (
    <EditFountain headers={headers} rows={fountainsToDisplay}></EditFountain>
  )
 }
  

    </div>

      <View margin="3rem 0">
      {/* onClick - either TWO parentheses ()=functioName() or NONE! */}
      {previousTokens !== null && previousTokens.length !== 0 ? (
        
                 <Button variant="outlined" onClick={onClickNextPage} color="secondary"> Prev </Button>
  
              ) : (
                <div></div>
              )}
         
          <View> 
          { !isEditView && !isSingleFountainView ? (
            <Button variant="outlined" onClick={onClickNextPage} color="secondary">Next </Button>) 
            : (<div>View/Edit</div>) }
            
            </View>


  
      </View>





           

              
      {/* <Button onClick={signOut}>Sign Out</Button> */}

      {isSingleFountainView === true ? (
      <div>
        <div>--</div>
        {/* show an image if value exist for non-database strapped on key .. */}
        {fountainsToDisplay[0].fullImageUrl && (
      <Image
        src={singleFountainFullImageUrl}
        alt={`visual aid for ${fountainsToDisplay[0].Location}`}
        style={{ width: 400 }}
      />
    )}
       
       
      </div>        ) : (
                  <div> -No image-- </div>
              ) 
      }   


      {isSingleFountainView === true ? (
      <div>
        <div>--</div>
        {/* colorTheme and variation see https://ui.docs.amplify.aws/react/components/button
        variation="primary" colorTheme="warning"  
        primary means FILL with color! */}
        <Button colorTheme="success" variation="primary" onClick={back2table}>List Fountains</Button>
        {!isEditView && <Button  variation="primary" colorTheme="warning" onClick={click2edit}>Edit Fountain</Button>}
        {!hideEditDeleteButtonsIfUnathenticated && <Button variation="primary" colorTheme="error" onClick={click2delete}>Delete Fountain</Button>}
       
       
      </div>        ) : (
                  <div> ---- </div>
              ) 
      }   

      {route !== 'authenticated' ? (
                  <Button onClick={() => navigate('/login')}>Login</Button>
                ) : (
                  <Button onClick={() => logOut()}>Logout</Button>
                )   }  


         { isDebugMode ? (
              <div>
                  <div> debugMode is ON. The states are:  </div>
                  <div> isSingleFountainView is {isSingleFountainView.toString()} </div>
                  <div> isEditView is {isEditView.toString()} </div>
                  <div> setHideEditDeleteButtonsIfUnathenticated is {hideEditDeleteButtonsIfUnathenticated.toString()}</div>
                  <div> isMobile is {isMobile.toString()}</div>
                  <div> menu_visible is {menu_visible.toString()}</div>
              </div>
                   ) : (
                  <div>Debug Mode OFF </div>
                )   }      
      
    </View>
  );
};

