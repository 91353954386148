// You’ll use this file for any actions on the /list endpoint. Add a function to retrieve the data using the fetch function:

// used IF API CALL FAILS (e..g OFFLINE ...)
const mockApiResponse = {items: 
  [
    {"eventKey":"“What can there be left for a commander in defeat? In antiquity they took poison” - German Field Marshal Model, before shooting himself in the head."},
    {"eventKey":"The Battle of Hurtgen Forest began. It would drag on for months and cost the lives of 33,000 American soldiers."},
    {"eventKey":"Having spent three years fighting on the Eastern Front, Hitler moved Field Marshal Walter Model to the Western Front to defend France against the Allies."},
    {"eventKey":"The losing British General at Saratoga, John Burgoyne, was later dubbed ‘the man who lost America’."},
    {"eventKey":"New York City based film Breakfast At Tiffany’s was released"}
  ]
};

// getList contains fetch URL:
// EXPORT SO CALLABLE FROM OTHER JS FILES!
  export function getList(endpoint) {

    console.log("This is services/call-api.js getList func: GET LIST function!!! ");
    console.log("CALL FETCH DOT THEN DATA ");
    console.log(`https://american-history-sql.appspot.com/api/`+endpoint);
    //return fetch('http://localhost:3333/list')
    return fetch(`https://american-history-sql.appspot.com/api/`+endpoint)
      .then(data => data.json())
      .catch(
      
        error => {console.log("There has been an error in fetching data from API!");
        console.log(error);
        console.log("FETCH ERROR SO RETURN mockApiRespsonse!");
        //alert("Error - might be no internet!");
        return(mockApiResponse);
      }
      );
  }
  
  // EXPORT SO CALLABLE FROM OTHER JS FILES!
  // Not sure what this is used for!
  export function setItem(item) {
    console.log("This is services/call-api  SET ITEM function");
    console.log("CALL FETCH WITH method and headers! DOT THEN DATA ")
   return fetch('http://localhost:3333/list', {
     method: 'POST',
     headers: {
       'Content-Type': 'application/json'
     },
     body: JSON.stringify({ item })
   })
     .then(data => data.json())
  }