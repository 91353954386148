import React, { useState, useEffect, useMemo } from "react";
// useMemo used for media queries in UMQ?
// npm install styled-components
import styled from "styled-components";

import { device } from './device';
// TEST LOCALLY with npm start

import { getList, setItem } from './../services/call-api';

import Container from 'react-bootstrap/Container';
// npm i --save react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const slidespeed = 10000; // make fast when testing! 

var globalLatestIntervalId = 0;
var randWholeNumber = 1;

const Main = styled("div")`
  font-family: sans-serif;
  background: #f0f0f0;
  height: 100vh;
`;

const divStyleXXL = {
  color: "#4a54f1",
  fontSize: '56px',
  paddingTop: "2px",
};

const divStyleXXXL = {
  color: "#4a54f1",
  fontSize: '80px',
  paddingTop: "2px",
};

const divStyleSmall = {
  color: "black",
  fontSize: '40px',
  paddingTop: "1px",
};


const divStyleMedium = {
  color: "green",
  fontSize: '48px',
  paddingTop: "1px",
};

// NOTE HOW backgroundColor NOT official css background-color!
// by contrast if you use BACKTICK style approach it IS official css
const styleEqualsRed100 = {
  backgroundColor: "red",
  width: "100px"
};

{/*It's FLOAT LEFT not align left!!  */}
const styleEqualsLeftAlign = {
  float: "left"
};

const styleEqualsGreen100 = {
  backgroundColor: "green",
  width: "100px"
};

const divStyleMobile = {
  color: "#FF0000",
  fontSize: '36px',
  paddingTop: "1px",
};

// note how ITS fontSize not official css font-size! 
const divStyleTiny = {
  color: "black",
  fontSize: '14px',
  paddingTop: "1px",
};


// If you use styleD BACKTICK style approach it IS official css
const DivParent = styled("div")`
  border: 1px solid black;
  margin: 1rem; 
  padding: 1rem 1rem;
  text-align: center;
`;


function PrintWidthDescription(props) {

  console.log("Component PrintWidthDescription");

  return( <div> Description of Screen: {props.descOfScreen} </div> );

}

function ReturnMainContent(props) {

        {/* VIP! THIS IS THE EVENT content! COLON = IF FALSE  */}
        {/* ? followed by another ? implies nested conditions! */}
        {/* First condition list.length just means if there IS some length (non- ie there are some items in the list  */}

        console.log("ReturnMainContent!!");

        console.log("props.mdgt800 is: ");
        console.log(props.mdgt800);

        console.log(`props.lggt1200 is: ${props.lggt1200} `);

        console.log("props.list.length is: ");
        console.log(props.list.length);

        console.log("props.counter: ");
        console.log(props.counter);

        let content;

        switch (true) {
          // note the NOT at least 800
          // notice how case doesn't use SMOOTH brackets like if statements but has COLON
          // CASE COLON! 
        case props.list.length && !props.mdgt800:
          console.log("md_at_least_800 is NOT true so likely narrow MOBILE!!!");
          content = <b style={divStyleMobile}>{props.list[props.counter].eventKey}</b>;
          break;
        case props.list.length && props.lggt1200:
          console.log("lg_at_least_1200  true so VERY WIDE SCREEN - big font OK!!");
          content = <b style={divStyleXXXL}>{props.list[props.counter].eventKey}</b>;
          break;
        case props.list.length && props.list[props.counter].eventKey.length > 160:
          console.log("Bigger than mobile but lots of chars so font size not too big!");
          content = <p style={divStyleSmall}>{props.list[props.counter].eventKey}</p>;
          break;
        case props.list.length && props.list[props.counter].eventKey.length > 110:
          console.log("Bigger than mobile MEDIUM font size please");
          content = <p style={divStyleMedium}>{props.list[props.counter].eventKey}</p>;
          break;
        case props.list.length > 0:
          console.log("VERY FEW CHARS so make XXL font size!");
          content = <p style={divStyleXXL}>{props.list[props.counter].eventKey}</p>;
          break;
        default:
          console.log("UH OH! DEFAULT!");
          content = <p>default loading - No cases matched! </p>;
        }



        return content;
}
// end RETURN MAIN CONTENT def.

const DivChild = styled("div")`
  display: inline-block;
  border: 1px solid red;
  padding: 1rem 0rem;
  vertical-align: middle;
`;

// inline = IN THE SAME LINE , NO NEW LINE BELOW
// NOTE THE SUBTLE BACKTICK
//const DropDownContainer = styled("div")`
//display: inline-block;
//  width: 6em;
//  margin: 0 auto;
//`;

// IMPORTANT LEARNING - if you go to "Computed" styles in Dev tools and see
// <style> as the SOURCE of a style then its becuase of these styled 

const DropDownContainer = styled("div")`
  display: flex;
  flex-flow: column;
  justify-content;
`;

const DropDownHeader = styled("div")`
  margin-bottom: 0.1em;
  padding: 0.4em 0em 0.4em 0em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  color: #3faffa;
  background: #ffffff;

  @media ${device.mobilepoc} { 
    font-size: 1.3rem;
  }

  @media ${device.threehundredandsixty} { 
    font-size: 1rem;
  }

  font-size: 0.8rem;
`;

const HistoryOf = styled("div")`
display: inline-block;
  width: 7em;
  margin: 0 auto;
  margin-bottom: 0.1em;
  padding: 0.4em 0em 0.4em 0em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
 
  color: #000000;
  background: #ffffff;


  @media ${device.mobilepoc} { 
    font-size: 1.3rem;
  }

  @media ${device.threehundredandsixty} { 
    font-size: 1rem;
  }

  font-size: 0.8rem;
`;

const DropDownListContainer = styled("div")``;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 0em;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: #3faffa;
  font-size: 1.3rem;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.4em;
`;

// THESE CONTS ARE OUTSIDE EVEN the function Dropdown! 



const optionsCountries = ["In The USA", "In Britain", "In France"];
// africa architecture asia books business central-america china crime death disaster disasters economics environment europe exploration justice media medicine middle-east movies music native-americans new-york politics presidents psychology quotes race religion russia science south-america sport tech war women writers
const ddOptionsTags = ["Everything", "Africa", "architecture","asia","books","business","central-america","china","crime","death","disasters","economics","environment","europe","exploration","justice","media","medicine","middle-east","movies","music","native-americans","new-york","politics","presidents","psychology","quotes","race","religion","russia","science","south-america","sport","tech","war","women","writers"];

const arrYesils = [{"eventKey":"“What can there be left for a commander in defeat? In antiquity they took poison” - German Field Marshal Model, before shooting himself in the head."},{"eventKey":"The Battle of Hurtgen Forest began. It would drag on for months and cost the lives of 33,000 American soldiers."},{"eventKey":"Having spent three years fighting on the Eastern Front, Hitler moved Field Marshal Walter Model to the Western Front to defend France against the Allies."},{"eventKey":"The losing British General at Saratoga, John Burgoyne, was later dubbed ‘the man who lost America’."},{"eventKey":"New York City based film Breakfast At Tiffany’s was released"}];

// DEFINE UMQ SINGULAR! ... called later from export function CompName()
// Use PASS IN a media query such as (min-width: 800px)
function useMediaQuery(query) {

  console.log("useMediaQuery SINGULAR (for one specific media q")
  const mediaQuery = useMemo(() => window.matchMedia(query), [query]);
  const [match, setMatch] = useState(mediaQuery.matches);
  

  // Get media query status - whether the query matches the current viewport.
  // Subscribe to the changes of media query status.
  // NOTE UE not called every time UMQ singular is called!
  useEffect(() => {
    const onChange = () => {
      console.log("UE inside UMQ SINGULAR onChange SCREEN WIDTH?");
      console.log("UE Call setMatch");
      setMatch(mediaQuery.matches);

    }
    mediaQuery.addEventListener("change", onChange);

    return () => mediaQuery.removeEventListener("change", onChange);
  }, [mediaQuery]); // NOTE USEEFFECT DEPENDENCY! useMemo? 
  

  console.log(`UMQ SINGULAR for ${query} returns: ${match}`);
  return match;
}

// UMQ PLURAL calls UMQ SINGULAR a few times ... 
function useMediaQueries() {
  console.log("USE MEDIA QUERIES PLURAL .. it calls singular a few times ...");
  
  const sm_at_most_760 = useMediaQuery("(max-width: 760px)");
  
  const md_at_least_800 = useMediaQuery("(min-width: 800px)");
  // Note 1200 is very wide! Hard to even trigger on macbook.
  const lg_at_least_1200 = useMediaQuery("(min-width: 1200px)");

  

  console.log("md_at_least_800 is");
  console.log(md_at_least_800);
  console.log("lg_at_least_1200 is");
  console.log(lg_at_least_1200);

 

  return { md_at_least_800, lg_at_least_1200 };
}
// end UMQ PLURAL! 


function convertMonthIntToMonthStr(monthInt) {

  if(monthInt === 1) {
    return "Jan "
  }else if(monthInt === 2) {
    return "Feb "
  }else if(monthInt === 3) {
    return "Mar "
  }else if(monthInt === 4) {
    return "Apr "
  }else if(monthInt === 5) {
    return "May "
  }else if(monthInt === 6) {
    return "Jun "
  }else if(monthInt === 7) {
    return "Jul "
  }else if(monthInt === 8) {
    return "Aug "
  }else if(monthInt === 9) {
    return "Sep "
  }else if(monthInt === 10) {
    return "Oct "
  }else if(monthInt === 11) {
    return "Nov "
  }else if(monthInt === 12) {
    return "Dec ";
  } else {
    return "";
  }
}


function howManyYearsAgo(yearInt) {

  const yearsAgo = 2023 - yearInt;

    // when returning JSX wrap any javascript in curlys! including combos of text and JS vars
    return <span style={divStyleTiny}>{"" + yearsAgo + " years ago"}</span>

}

// EXPORT MARKS THE SPOT!!! 
export function Dropdown() {

  // OAF in react = outside functions that are inside the main function Dropdown()
  console.log("OAF! - EXPORT MARKS THE SPOT- function Dropdown()");
  console.log("OAF! - EXPORT MARKS THE SPOT function Dropdown()");
  console.log("EXPORT MARKS THE SPOT = A NEW ROUND OF RERENDERING has been started!!!");
  
  // KEY CONCEPT - Apart from the first render, on subsequent re-renders only code in OAF gets called plus return HTML.
  // Post first render, Code inside top level UseEffects will only get called if the state they are dependent on changes due to setX(new value) being called
  
  // STATES .... initial values passed into useState() ONLY set on 1st render. Ignored in subsequent renders
  const [isOpenCountries, setIsOpenCountries] = useState(false);
  const [selectedOptionCountries, setSelectedOptionCountries] = useState(null);
  const [isOpenTopics, setIsOpenTopics] = useState(false);
  const [selectedTagOption, setSelectedTagOption] = useState(null);

  const [screenString, setScreenString] = useState(" ");

 
 // FOR RESPONSIVE DESIGN
 // CALL UMQ PLURAL from inside export function ComponentName()
  // CALL UMQ PLURAL from inside export function ComponentName()
  console.log("From inside export function CompName() call UMQ PLURAL ...");
  const { md_at_least_800, lg_at_least_1200 } = useMediaQueries();

  
  // THESE TOGGLES WILL BE PASSED INTO onClick ... 
  // set up a function that will be called not immediately but passed into onClick
  const toggleOpenCloseTopics = () => {
    console.log("toggleOpenCloseTopics - Should be triggered when dropdown header is clicked.");
    console.log("toggleOpenCloseTopics - TOGGLE ISOPEN");
    console.log("About to call setIsOpenTopics");
    console.log("Note that state isOpenTopics is used inside <Dropdown")
    setIsOpenTopics(!isOpenTopics);
  }

  const toggleOpenCloseCountries = () => {
    console.log("Should be triggered when dropdown header is clicked.");
    console.log("toggleOpenCloseCountries - TOGGLE ISOPEN");
    setIsOpenCountries(!isOpenCountries);
  }

  // DEFINE HOW 2 RESPOND TO CLICK OF AN ITEM IN A DROPDOWN ...
  const onOptionClickedTopics = value => () => {
    console.log("ON OPTION CLICKED");
    console.log("onOptionClickedTopics");
    console.log("latestIntervalId is: ");
    console.log(globalLatestIntervalId);
    console.log("LETS CLEAR THE EXISTING INTERVAL B4 SETTING UP NEW ONE! ");
    clearInterval(globalLatestIntervalId);
    console.log("selectedTagOption state value BEFORE set called with new value is: ");
    console.log(selectedTagOption);


    // apiQuery
    console.log("apiQuery STATE - state value BEFORE set called with new value is: ");
    console.log(apiQuery);


    console.log("The value clicked on was: ");
    console.log(value);
    console.log("BIG MOMENT! CALL setSelectedTagOption ");
    console.log("ITS BIG cos selectedTagOption  is DEPENDENCY for 2nd UseEffect");
    setSelectedTagOption(value); // causes 

    const query = "tags/"+value;
    console.log("NEW query based on clicked on option");
    console.log(query);
    console.log("About to call setApiQuery passing in new chosen option");
    console.log("Recall calling setX just updates stored state value of X unless X is a dependency of a useEffect.");
    
    setApiQuery(query);

    console.log("CLOSE dropdown once user has clicked choice, pass FALSE INTO setIsOpenTopics");
    setIsOpenTopics(false); // close it after user clicked an option!  
    
  }; // end clicked topic 

  const onOptionClickedCountries = value => () => {
    console.log("Should be triggered when an individual ITEM or OPTION or ELEMENT inside a dropdown is clicked!.");
    console.log("onOptionClickedCountries");
    console.log("selectedOptionCountries state value BEFORE set called with new value is: ");
    console.log(selectedOptionCountries);
    console.log("The value clicked on was: ");
    console.log(value);
    setSelectedOptionCountries(value);
    setIsOpenCountries(false); 
  };


 //   OAF ... 
  
  //const Unp = ({ signOut }) => {
    console.log("OAF - SLIDESHOW STUFF IN BELOW DROPDOWNS!");
    // SET UP STATE !!! VIP VIP VIP
    const [yesilCounter, setYesilCounter] = useState(0);
    const [counter, setCounter] = useState(0);
    const [counterLessOne, setCounterLessOne] = useState(0);
    const [numEntries, setNumEntries] = useState(0);

    // INITIAL VALUE AVAILABLE STRAIGHT AWAY



   

    // CALL (top-level) UE ...
    // useEffect seems to be stuff you call for FIRST RENDER
    // example, go fetch data you need to show! 
    // 2nd parameter of useEffect often seems to be empty array.
    useEffect(() => {
      console.log("THIS is useEffect for SET UP 4 INITIAL RENDER - useEffect WITH NO DEPENDENCIES");
      console.log("useEffect WITH NO DEPENDENCIES - empty array []");
      console.log("Means ONLY CALLED ON FIRST PAGE LOAD");

     

     
  
      //updateYesilShown();
  
     
      // SEE EMPTY [] BELOW ... no dependencies ...
     
      }, []); // Or [] if effect doesn't need props or state
      // END OF UE CALL.

      // CHOOSE RANDOM OPTION/TAG/SUBTOPIC for 1st render
      // WILL BE ignored on subsequent re-renders rounds! 
      console.log("BELOW is a bit confusing as will be ignored apart from on 1st render due to SIV rule!");
      
      console.log("Lets set random INDEX for Options / Tag / Subtopic - wont be used in re-renders ");
      let maxLimit = ddOptionsTags.length;
      console.log("maxLimit for dropdown options is: ");
      console.log(maxLimit);
      let rand = Math.random() * maxLimit;
      randWholeNumber = Math.floor(rand);
      console.log(`random index (for options) is`);
      console.log(randWholeNumber);
      console.log("randWholeNumber (for options) is: ");
      console.log(randWholeNumber);
      console.log(`If setting Initial Tag/Subtopic on FIRST RENDER it will be: tags/${ddOptionsTags[randWholeNumber]}`)

      // INITIAL VALUE IS ONLY SET ONCE ... (well technically twice but only 2nd time sticks.)
      // const [apiQuery, setApiQuery] = useState("anniversary?ago1=1&ago2=2&ago3=3&ago4=4&ago5=5&ago6=10&ago7=15&ago8=20&ago9=30&ago10=40");
      console.log("key thing to understand about REACT LIFECYCLES ...  ")
      
      console.log("OAF: Even if the program runs past this codeline multiple times, the  INITIAL value 4 a state (passed into useStage(SIV)) ONLY HAPPENS ONCE ON INTIAL PAGE LOAD - is ignored on subsequent render rounds where state memory used instead thank god ")
      console.log("OAF: The ONLY ONCE set initial values for a state (SIVS) rule ");
      console.log("OAF: create initial (random) value for state apiQuery (ie. RANDOM OPTION / TAG / SUBTOPIC) ");
      // CRUCIAL INSIGHT ... initial value passed into useState will ONLY be used on FIRST RENDER
      // VIP Subsequent renders, the value stored in the state will be used, NOT the things after useState(SIV)
      const [apiQuery, setApiQuery] = useState("tags/"+ddOptionsTags[randWholeNumber]);
      console.log("OAF - apiQuery THIS RENDER ROUND will be");
      console.log(apiQuery);

      // REMEMBER value passed into useState ONLY USED ON 1ST RENDER! 
    const [count, setCount] = useState(0);
  
    const [visibleYesil, setYesil] = useState([]);
  
    // API ... 
    const [alert, setAlert] = useState(false);
    const [itemInput, setItemInput] = useState('');
    const [list, setList] = useState([]);

      // SECOND USEEFFECT - for getting API DATA! 
//This is services/list.js GET LIST function!!! 
// CALL FETCH DOT THEN DATA 
// THIS is useEffect for SET UP 4 INITIAL RENDER
// SECOND USEEFFECT - for getting API DATA! 
// This is services/list.js GET LIST function!!! 
// CALL FETCH DOT THEN DATA 
// 0 data returned by api was
  
  // CALL UE ...
      useEffect(() => {
  
        console.log("SECOND USEEFFECT - for getting API DATA! ");
        console.log("This SECOND USEEFFECT DOES have a dependency ");
        console.log("Dependency ON selectedTagOption");

        console.log("This means that this will get called both on initial render AND IF selectedTagOption state changes via setSelectedTagOption");


        console.log("apiQuery");
         console.log(apiQuery);

        let mounted = true;
        // TODO - hardcoded topic name! 
        getList("american/"+apiQuery)
          .then(dataFromApi => {
            
            console.log("=========== ");

            console.log("===getList DOT THEN === ");

            console.log("LETS PROCESS RESPONSE FROM API! ");

            if(mounted) {
              console.log("mounted");
              console.log("AFTER DELAY - data returned by api was");
              console.log(dataFromApi);
              console.log(dataFromApi.items); // if api returns an object containing array of objects { items = [] } so first peel of items wrapper to get to array of objects.
              console.log(dataFromApi.items[0]);
              console.log("Remove outer items key");

              setList(dataFromApi.items);

              console.log("CURRENT round / iteration numEntries state is: ");
              console.log(numEntries);

              console.log("dataFromApi.items.length");
              console.log(dataFromApi.items.length);
              console.log("\nABOUT TO CALL setNumEntries: ");
              setNumEntries(dataFromApi.items.length);
              
              // must hit refresh if changing screenwidth. 
              setScreenString(`800${md_at_least_800}, 1200${lg_at_least_1200}`)

              console.log("\nABOUT TO CALL triggerSlideshowWithData: ");
              triggerSlideshowWithData(dataFromApi.items);
  
            } else {
              console.log("NOT mounted?");
            }
          })
          //console.log("return () => mounted = false;")

          // A return statement is used to return a cleanup function. This cleanup function sets the mounted variable to false when the component is unmounted. This is used to prevent state updates if the component is unmounted before the API response is received.

// The cleanup function is called automatically by React when the component is unmounted.
        return () => {
          console.log("return() => mounted = false");
          mounted = false;}
    
       // if(alert) {
       //   setTimeout(() => {
       //     setAlert(false);
       //   }, 1000)
       // }
          
       // see NON EMPTY [] BELOW
       // this useEffect has dependency on selectedTagOption
       // useEffect dependency determines WHEN this useEffect code will get called! 
        
       // below means if selectedTagOption this UE code gets called!
      }, [selectedTagOption]) // empty array implies no useEffect DEPENDENCIES (call useEffect when they change)
      // END OF UE CALL!! 



  
  function triggerSlideshowWithData(dataForSlideshow) {

    console.log("CALL  setInterval(() !!!!! ")
    const interval = setInterval(() => {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/Arrow_functions
      // prevState is provided by React along with props , both of which are optional.
      // WHERE DOES prevCounter come from (ANSWER react provides the current state)
      // Update 04/13/19: React has changed the setState function documentation by renaming prevState to updater. The callback function still takes two arguments; the state and props at the time the change is being applied.
      // https://legacy.reactjs.org/docs/react-component.html#setstate
      // Think of setState() as a request rather than an immediate command to update the component. 
      // For better perceived performance, React may delay it, and then update several components in a single pass.
      //  If the next state depends on the current state, we recommend using the updater function form, instead:
      setCounter((prevCounter) => {
        console.log("Inside setInterval that was initially set off inside useEffect - prevCounter provided by react and is the current state value");
        console.log(prevCounter);
        console.log(dataForSlideshow);
        if(prevCounter == dataForSlideshow.length-1) {
          return  0
        } else {
          return prevCounter % dataForSlideshow.length  + 1}
        }
      );

      // arrow func ... function(previousCounter) { return prevCounter % arrYesils.length  + 1}
      
    }, slidespeed);

    console.log("interval ID is: ");
    console.log(interval);


    globalLatestIntervalId = interval;
    console.log("latestIntervalId is: ");
    console.log(globalLatestIntervalId);


    // When there is no function body (no curly brace {}) then you are returning IMPLICITYLY:
    return () => { 
      console.log("Call clearInterval");
      clearInterval(interval);
    }


  }
  // END TRIGGER SLIDESHOW WITH DATA
  
    
     
  function nextSlide() {
    console.log("Next button clicked!");
    console.log("state numEntries is: ");
    console.log(numEntries);

    console.log("latestIntervalId is: ");
    console.log(globalLatestIntervalId);

    
    setCounter((stateForCounter) => {
      console.log("stateForCounter provided by setC: ");
        console.log(stateForCounter);
      if(stateForCounter == numEntries-1) {
        return  0
      } else {
        return stateForCounter % numEntries  + 1}
      }
    );



    //setCounter
  }
  // END NEXTSLIDE()

  function removeTagsForwardSlash(iprTagQuery) {

    if(iprTagQuery){
      var retainTagBit = iprTagQuery.replace("tags/",'');
      return retainTagBit
    } else {
      return "Tag Unknown"
    }

  }

  function prevSlide() {
    console.log("Previous button clicked!");
    console.log("state numEntries is: ");
    console.log(numEntries);

    console.log("latestIntervalId is: ");
    console.log(globalLatestIntervalId);

    
    setCounter((stateForCounter) => {
      console.log("stateForCounter provided by setC: ");
        console.log(stateForCounter);
      if(stateForCounter == 0) {
        return  numEntries-1
      } else {
        return stateForCounter % numEntries - 1}
      }
    );
    //setCounter
    //npm start
  }



  // finally RETURN SOME CONTENT ... 
  return (
    <Main>

        {/* <Heading level={2}>Current Fountains</Heading> */}


    <header className="bg-gradient-poc short-please" id="fountains">

  
    </header>

<div class="container mt-2 g-0">
<div class="row g-0">
  
<div id="verty" class="col-4">
      
     <HistoryOf> The History Of  </HistoryOf>
   
      </div>   

      <div id="verty" class="col-4">
      
      {/* Native html uses select and option elements but this uses styled DIVS */}

       <DropDownContainer >
        {/* Distinguish btwn clicking the dropdown header and a specific option */}

        <DropDownHeader onClick={toggleOpenCloseTopics}>
          {selectedTagOption || "Everything"}
        </DropDownHeader>
        {isOpenTopics && (
          <DropDownListContainer>
            <DropDownList>
              {ddOptionsTags.map(option => (
                <ListItem onClick={onOptionClickedTopics(option)} key={Math.random()}>
                  {option}
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>

            
      
  
      </div>  


      <div id="verty" class="col-4">
      
      <DropDownContainer>
        <DropDownHeader onClick={toggleOpenCloseCountries}>
          {selectedOptionCountries || "In The USA"}
        </DropDownHeader>
        {isOpenCountries && (
          <DropDownListContainer>
            <DropDownList>
              {optionsCountries.map(option => (
                <ListItem onClick={onOptionClickedCountries(option)} key={Math.random()}>
                  {option}
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    
    
      </div>  

      </div>
      </div>

      <Container>
      
     
        
      <Row>
         {/* To have multiple bits of JSX one after other wrap in {} and space separate {xxx} {yyy} */}
          {/* Below has Month, Year, years ago and tag all inside the Col element */}
        <Col> <h1> {list.length ? convertMonthIntToMonthStr(list[counter].monthInt) + list[counter].yearInt : "loading ..."}  {list.length ? howManyYearsAgo(list[counter].yearInt) : ""} {list.length ? removeTagsForwardSlash(apiQuery) : ""}</h1> </Col>
      </Row>
    </Container>

  <div className="App" id="maincontent" onClick={ ()=> {nextSlide();} }>
     
    
      <h1>

    <ReturnMainContent list={list} counter={counter} mdgt800={md_at_least_800} lggt1200={lg_at_least_1200}></ReturnMainContent>
     
    
      </h1>
     

     
    </div>

    <button id="a-little-margin-on-the-sides"onClick={ ()=> {
      prevSlide();
      } } > Previous </button>

          <button className="a-little-margin-on-the-sides" onClick={ ()=> {
            nextSlide();
        } } > Next </button>

    <h6 id="counter">Counter: {counter}</h6>
    <h6 id="Tag">{apiQuery || "Randomizing Tag"}</h6>

   
    {list.length ?  <a href={ "https://american-history-sql.appspot.com/"+"american/"+list[counter].id+"edit"}> EDIT  </a>
    : <b></b> }


  {/* IF LOTS OF CHARS, SMALLER FONT */}

   {/* CONDITION ? TRUE : FALSE */}
{/* ? followed by another ? implies nested conditions! */}
{/* First condition list.length just means if there IS some length (non- ie there are some items in the list  */}

  {/* below is JUST FOR NUM CHARS (=eventKey.length) INDICATOR!. scroll up for actual content! */}
{/* Any html 2 return in TERNARY wrap in smooth ()  */}


{list.length ? list[counter].eventKey.length > 110 ? (
        <b style={divStyleSmall}>{list[counter].eventKey.length}</b>
      ) : md_at_least_800 ? (
        <p style={divStyleSmall}> {list[counter].eventKey.length}</p>
    ) :  (
      <p style={divStyleXXL}> {list[counter].eventKey.length}</p>
  ) : <p> loading</p> }


  <div style={styleEqualsLeftAlign}>
    <span style={styleEqualsRed100}>100px</span>
    <span style={styleEqualsGreen100}>200px</span>
    <span style={styleEqualsRed100}>300px</span>
    <span style={styleEqualsGreen100}>400px</span>
    <span style={styleEqualsRed100}>500px</span>
    <span style={styleEqualsGreen100}>600px</span>
    <span style={styleEqualsRed100}>700px</span>
    <span style={styleEqualsGreen100}>800px</span>
    <span style={styleEqualsRed100}>900px</span>
    <span style={styleEqualsGreen100}>1000px</span>
    <span style={styleEqualsRed100}>1100px</span>
    <span style={styleEqualsGreen100}>1200px</span>
    <span style={styleEqualsRed100}>1300px</span>
    <span style={styleEqualsGreen100}>1400px</span>
    <span style={styleEqualsRed100}>1500px</span>
    <span style={styleEqualsGreen100}>1600px</span>
    <span style={styleEqualsRed100}>1700px</span>
    <span style={styleEqualsGreen100}>1800px</span>
    <span style={styleEqualsRed100}>1900px</span>
    <span style={styleEqualsGreen100}>2000px</span>
    <span style={styleEqualsRed100}>2100px</span>
    <span style={styleEqualsGreen100}>2200px</span>
    <span style={styleEqualsRed100}>2300px</span>
    <span style={styleEqualsGreen100}>2400px</span>
    <span style={styleEqualsRed100}>2500px</span>
    <span style={styleEqualsGreen100}>2600px</span>
    <span style={styleEqualsRed100}>2700px</span>
    <span style={styleEqualsGreen100}>2800px</span>
    <span style={styleEqualsRed100}>2900px</span>
    <span style={styleEqualsGreen100}>3000px</span>
    <span style={styleEqualsRed100}>3100px</span>
    <span style={styleEqualsGreen100}>3200px</span>
  </div>



<PrintWidthDescription descOfScreen={screenString}> </PrintWidthDescription> 

  

    {/* CONTROL BACKTICK then ... npm start .. {{marginRight: spacing + 'em'}} */}

    {/* (
        <p style={divStyleXXX}> {list[counter].eventKey.length}</p>
    )  */}


      {/* git add , git push origin main etc. */}

        {/* If SSH issues try  */}



    </Main>
    
  );
}