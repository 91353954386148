// components/Home.js
import React, { useState, useEffect } from "react";
import "../App.css";
import "@aws-amplify/ui-react/styles.css";

import { API } from "aws-amplify";

// No map resources found in amplify config, run 'amplify add geo' to create one and run `amplify push` after
// THERE should be GEO section in aws-exports.js
import { createMap, createAmplifyGeocoder } from "maplibre-gl-js-amplify"; 
// npm i maplibre-gl-js-amplify@2.1.0 
import "maplibre-gl/dist/maplibre-gl.css";
// npm i maplibre-gl@2.1.9
// 
//import { useEffect } from 'react';

import { drawPoints } from "maplibre-gl-js-amplify";

// import { Heading } from '@aws-amplify/ui-react';
import {
  Button,
  Flex,
  Heading,
  Text,
  View,
} from "@aws-amplify/ui-react";

import { listDersonalFountains as listFountains } from "../graphql/queries";



export function Map({ signOut }) {
//const Unp = ({ signOut }) => {
  console.log("sTHIS IS Map.js COMPONENT!");
  // SET UP STATE !!! VIP VIP VIP
  const [notes, setNotes] = useState([]);

  // useEffect seems to be stuff you call for FIRST RENDER
  // example, go fetch data you need to show! 
  // 2nd parameter of useEffect often seems to be empty array.
  useEffect(() => {
    console.log("THIS is useEffect for SET UP 4 INITIAL RENDER");
    async function fetchData() {
      // You can await here
      const map = await initializeMap();
      // call addRest ... 
      console.log("STUFF in useEffect runs after every render!")
      console.log("FROM useEffect call addFountainLocationsToMap passing in MAP!");
      addFountainLocationsToMap(map);

      return function cleanup() {
        map.remove();
      };
      // ...
    }
    fetchData();
    }, []); // Or [] if effect doesn't need props or state
    // END OF USEEFFECT.

  async function fetchNotes() {
    console.log("THIS is fetchNotes ... lets call the graphQL API passing in query:")
    const apiData = await API.graphql({ query: listFountains }); // no mention of graphqloperations! 
    // STRIP OUT OUTER KEYS of data: and items:
    const notesFromAPI = apiData.data.listFountains.items;
    console.log("Queue up an Update to the UI by calling setSomeState ...");
    setNotes(notesFromAPI);
  }


  async function initializeMap() {
    console.log("this is initializeMap!!! - CREATE a map const! ");
    console.log("When initializing map you can pass in CENTER co-ordinats AND zoom level (e.g. 11) ! ");
    const map = await createMap({
        container: "map", // An HTML Element or HTML element ID to render the map in https://maplibre.org/maplibre-gl-js-docs/api/map/
        center: [-0.1111, 51.5151], // [Longitude, Latitude]
        zoom: 11,
    })

    map.addControl(createAmplifyGeocoder());

    return map;
  }

  function addFountainLocationsToMap(map) {
    console.log("This is addFountainLocationsToMap");
  
    console.log("POP 3 hardcoded locations with coordinates into the map! ");
    map.on("load", function () {
  
      drawPoints("mySourceName", // Arbitrary source name
          [
              {
                coordinates: [-0.1111, 51.51515],
                title: "First Fountain",
                address: "Ludgate Hill",
              },
              {
                coordinates: [-0.2222, 51.5555],
                title: "Second Fountain",
                address: "725 9th Ave, New York, NY 10019",
              },
              {
                coordinates: [-0.2333, 51.5666],
                title: "Drinking Trough",
                address: "Mornington Crescent",
              },
          ], // An array of coordinate data, an array of Feature data, or an array of [NamedLocations](https://github.com/aws-amplify/maplibre-gl-js-amplify/blob/main/src/types.ts#L8)
          map,
          {
              showCluster: true,
              unclusteredOptions: {
                  showMarkerPopup: true,
              },
              clusterOptions: {
                  showCount: true,
              },
          }
      );
    });
  }
  

 

  return (
    <>
    <header className="bg-gradient-poc short-please" id="fountains">
        <div className="container mt-5">
            <h1>Map of Our Fountains</h1>
               </div>
       
    </header>

    <div id="map"></div>

      <View margin="3rem 0">
        {notes.map((note) => (
          <Flex
            key={note.id || note.name}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Text as="strong" fontWeight={700}>
              {note.name}
            </Text>
            <Text as="span">{note.description}</Text>
          
          </Flex>
        ))}
      </View>
      <Button onClick={signOut}>Sign Out</Button>
      </>
  );
};

