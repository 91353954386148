//import { Heading } from '@aws-amplify/ui-react';
// REMEMBER NO DOUBLE SPACES AS IT CONFUSES ERROR MESSAGES!!! 
import { Search} from './Search'

import { useState, useEffect } from 'react';

import {sculptureList} from './hardcodedData.js';

import { Dropdown, Option} from "./DropdownX";

import ApplyForGrantSection from './ApplyForGrantSection'; // Make sure the path is correct
import GrantsFAQs from './GrantsFAQs'; // Make sure the path is correct
import AnnualReports from './AnnualReports'; // Make sure the path is correct

//import Button from '@mui/material/Button';

//import PostTitles from "./PostTitles";

//import Box from '@mui/material/Box';
//import Tabs from '@mui/material/Tabs';
//import Tab from '@mui/material/Tab';
//import Container from '@mui/material/Container';
//import Typography from '@mui/material/Typography';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Carousel from 'react-bootstrap/Carousel';

import Card from 'react-bootstrap/Card';

//import 'holderjs';

//import apiData from '../services/wordpressApi';
//import apiFetch from '@wordpress/api-fetch';
//import parse from "html-react-parser";


import {
  
  Flex,
  Heading,
  Text,
  TextField,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";

// Xport marks the X
export function Home() {

  const result = sculptureList; 
  const [index, setIndex] = useState(0);
  const [elements, setElements] = useState([]);

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newTabIndex) => {
    console.log(event);
    console.log("Handle material UI tab change.");
    setTabIndex(newTabIndex);
  }

  const a11yProps = [
{"name":"Paul"},
{"name":"David"},
{"name":"Jonny"},

  ]

  const getAllElementsToState = async () => {
    //const result = await API.graphql(graphqlOperation(listPictures));
    //let imageArray = await buildImageArray(result.data.listPictures.items);

    // SEE docs.amplify.aws/GUIDES/api-graphql/graphql-pagination/q/platforms/js
    // FORMAT OF RESP FROM API.graphql(graphqlOperation(listX:
    // { data { "listTodos" { "items": [ blah blah]} , "nextToken": <token-id>}}

    const result = sculptureList;
    setElements(result);
  };

  useEffect(() => {
    getAllElementsToState();
  }, [] );

  function handleNextClick() {
    setIndex(index + 1);
  }

  // TO PASS IN PARAMETERS for onClick use () => funcName(iprX)
  function handleAddClick(iprInputtedText) {
    alert("ADD an element!");

//  const addTagImage = async (imageId, tagValue) => {
    // First i need all the tags for that image
    // IN THE FOOBAR image example, you need to pass in an id as adding text to an EXISTING image / picture whereas what we want here is crate a new row func.
    //const image = images.filter((value, index, arr) => {
    //  return value.id === imageId;
    //});

    // In Foobar's add tag to existing image you check existing tags and add one more! 
    // let labels = image[0].labels; // labels are TAGS.
    // DOT PUSH to add a new element to an existing array.
    // labels.push(tagValue);

    //const input = {
    //  id: imageId,
    //  labels: labels,
    //};

    const newElement = {
      id: "999",
      name: iprInputtedText,
      artist:'ARTIST OF ADDED ELEMENT',
      description: 'DESCRIPTION OF ADDED ELEMETNT ',
      url: 'http://wwW.wri.st',
      alt: 'alt textg '
    }

    // ...SPREAD OPERATOR ... allows you to take existing array and add another element to create a NEW ARRAY.
    setElements([...elements, newElement ]);

    //try {
    //  await API.graphql(graphqlOperation(updatePicture, { input: input }));

      //Then I need to refresh the state with the new tag
    //  await getAllImagesToState();
    //} catch (error) {
    //  console.log(error);
    //  alert("Cannot edit: User doesn't own this image");
    //}
//  };
  }

  // Remember you cannot call funcs() directly in JSX - ie. don't use smooth brackets funcName() unless you have () => before funcName()
  // pass them dont call them! 
  function handleDeleteClick(idPassedIn) {
    alert(`DELETE an element with id ${idPassedIn} from visible list!`);

    const id = {
      id: idPassedIn,
    };

    // in PRO you would have call to API
    const subsetElements = elements.filter((value, index, arr) => {
      return value.id !== idPassedIn;
    });

    console.log(subsetElements);

    console.log("CALL setElements to UPDATE THE UI! ");
    setElements(subsetElements);
  }

  const getAndProcessSearchResults = async (searchLabel) => {

    console.log("THIS IS getAndProcessSearchResults defined in Home parent comp!");

    var result;

    // when no search filter is passed, revert back to full list
    if (searchLabel.label == "") {

      console.log("User is searching for empty!!! DO NOTHING");
     // await getAllImagesToState();
    } else {
      // search
      const filter = {
        labels: {
          match: {
            labels: searchLabel,
          },
        },
      };

     // result = await API.graphql(
     //   graphqlOperation(searchPictures, { filter: filter })
     // );

     console.log(elements);

     var results = elements.filter( ele => ele.name.includes(searchLabel))

     console.log(results);
     console.log(results.length);

      //if (result.data.searchPictures.items.length > 0) {
      if (results.length > 0) {
       // let imageArray = await buildImageArray(result.data.searchPictures.items );

       console.log("There were RESULTS to the search!");
        //setImages(imageArray);

        setElements(results);

      } else {

        console.log("There were NO results to the search SET empty array!");
        //setImages([]);

      }
    }
  };
  // END OF GET SEARCH RESULTS

  let inputtedText = "this is hardcoded inputted text";
  let oneSculpture = sculptureList[index];

  return (
    <>




<header className="bg-gradient-poc" id="home">
        <div className="container mt-5">
            <h1>The Drinking Fountain Association</h1>
            <p className="tagline">Founded in 1859, the objectives of the Association are to promote the provision of drinking water for people and animals in the United Kingdom and overseas, and the preservation of the Association’s archive materials, artefacts, drinking fountains, cattle troughs and other installations. </p>
        </div>
        <div className="img-holder mt-3"><img src="./london_wall.jpg" alt="phone" className="img-fluid"></img></div>
    </header>
   
   {/*} <Heading level={3}>
      Please use the buttons at the top to test out protected routes!
  </Heading> */}


<div class="section light-bg" id="whatwedo">





<div class="container">

    <div class="section-title">
        <small>HIGHLIGHTS</small>
        <h3  className="h3-purple">What we do</h3>
    </div>


    <div class="row">
    {/* col-lg-4 makes it small narrow box 4 for a quarter? */}
        <div class="col-12">
         {/* card features is the border */}
            <div class="card features">
                <div class="card-body">
                    <div class="media">
                        
                        <div class="media-body">
                           
                            <p class="card-text">Every year we give grants for the installation of new drinking water fountains. We also provide grants for the restoration of existing fountains.  </p>
                            <p class="card-text">You can search through our database of over 6000 fountains. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      
      
    </div>

</div>



</div>
   {/*  // end .section  */}

   <div class="section light-bg" id="history">
        <div class="container">
            <div class="section-title">
                <small>HISTORY</small>
                <h3  className="h3-purple">History of the Association</h3>
            </div>

      
           
    {/*  TABS PART OF REACT-BOOTSTRAP  */}
    {/*  Known as uncontrolled tabs, no need for onClick  */}
    <Tabs
      defaultActiveKey="profile"
      id="fill-tab-example"
      className="mb-3"
      fill
    >
      <Tab eventKey="home" title="The Beginning">

    
              <div class="d-flex flex-column flex-lg-row">
                        <img src="/first.jpg" alt="graphic" class="img-fluid rounded align-self-start mr-lg-5 mb-5 mb-lg-0"></img>
                        <div>

                            <h2>The Beginning</h2>
                            <p class="lead">1859 </p>
                            <p>When the Association was set up in London in 1859 it was against a background of a filthy river Thames full of untreated sewage, rubbish and effluent from factories, water borne cholera, but most importantly inadequate free drinking water. An article in Punch magazine at the time of the Great Exhibition in 1851 said ‘Whoever can produce in London a glass of water fit to drink will contribute the best and most universally useful article in the whole exhibition’. Then in 1858 a paper read to the National Association for the Promotion of Social Science on the work being done to improve sanitary arrangements provoked much nation interest, and Samuel Gurney M.P rapidly took up its comments. He set up the Metropolitan Free Drinking Fountain Association (as it was then called) in 1859. Prince Albert wrote conveying his deep interest in the objects of the Association. Others giving their support included the Archbishop of Canterbury and a number of other prominent people.
                            </p>
                            <p> So it was the first fountain was unveiled, on 21 April 1859, at the boundary wall of St.Sepulchre’s church, Snow Hill before a large enthusiastic crowd. (In fact it was recorded for posterity in an engraving, which appeared in the Illustrated London News as shown below).
                            </p>
                            <p> Within a short time it was being used daily by around 7,000 people. Philanthropists started to contribute funds, and so more fountains were erected, and within 11 years there were 140 fountains in place, in addition to 153 cattle troughs. In 1867 the Association’s official title was changed to include ‘cattle troughs’ when the Association decided they must help the plight of animals who could be driven to London markets for days, without water. By 1885 over 50,000 horses were drinking daily from the Association’s troughs in London.
                            </p>
                        </div>
                    </div>

              

      </Tab>
      <Tab eventKey="profile" title="20th Century">

      <img src="./fountain-map-1912.png" alt="graphic" class="img-fluid rounded align-self-start mr-lg-5 mb-5 mb-lg-0"></img>
                       <div>
                            <h2>20th Century</h2>
                            <p class="lead">Government Gets Involved </p>
                            <p>Changes came at the beginning of the 20th century with local authorities taking responsibility for the maintenance of fountains and troughs, and also paying the appropriate water rates. Another change that came was in the 1930s. People were moving away from drinking from the same cup as other persons, and using cup less fountains.
                            </p>
                            <p> After the Second World War people had more leisure time and the demand for fountains increased particular for recreation grounds and parks. It was at this time the Association held a design competition for a reasonably priced fountain to suit the modern taste, rather than the expensive free standing granite structures that were erected in the 1870s - 1890s.
                            </p>
                            <p> In 1973 Du Pont invented the Polyethylene terephthalate (PET) bottle.
                            </p>
                           

                        </div>
                        <img src="./jerusalem1937.png" alt="graphic" class="img-fluid rounded align-self-start mr-lg-5 mb-5 mb-lg-0"></img>
                

      </Tab>
      <Tab eventKey="contact" title="Today">
                  <img src="./fountains-db.webp" alt="graphic" class="img-fluid rounded align-self-start mr-lg-5 mb-5 mb-lg-0"></img>
                        <div>
                            <h2>Today</h2>
                            <p class="lead">21st Century </p>
                            <p>Now approaching its 165th year the Association has provided over 4,000 drinking fountains, nearly 1,000 cattle troughs and 40 water wells overseas since 1859.
                            </p>
                            <p>Considerable changes have taken place since 1859 and the Association, together with its artifacts, is now part of the history of the metropolis. Mindful of this, many of the documents and records have been placed in the Museum of London for safekeeping and continued public interest. One of the early drinking fountains has been restored and moved to the museum where it forms the namepiece in the garden of the newly opened Fountain Restaurant.
                            </p>
                            <p>In 2018, the Mayor of London began installing the first of 110 new drinking fountains. By 2022, the busiest of the new fountains (at Camden High St) was dispensing 227 litres a day.
                            </p>
                            <p>In 2024, the Drinking Fountain Association moved its database of drinking fountains and troughs online so that anyone can browse the list of fountains.
                            </p>
                           
                        </div>
      </Tab>
    </Tabs> 


        </div> {/*  // end HISTORY CONTAINER  */}


    </div>
       {/*  // end .section  HISTORY */}




   <div class="section light-bg" id="projects">
        <div class="container">
            <div class="section-title">
                <small>PROJECTS</small>
                <h3  className="h3-purple">Projects</h3>
            </div>

      
           
{/*  TABS PART OF REACT-BOOTSTRAP  */}
{/*  Known as uncontrolled tabs, no need for onClick  */}
    <Tabs
      defaultActiveKey="profile"
      id="fill-tab-example"
      className="mb-3"
      fill
    >
      <Tab eventKey="home" title="Richmond">

    
              <div class="d-flex flex-column flex-lg-row">
                        <img src="./richmond.jpg" alt="graphic" class="img-fluid rounded align-self-start mr-lg-5 mb-5 mb-lg-0"></img>
                        <div>

                            <h2>Richmond Park</h2>
                            <p class="lead">The Richmond Park Drinking Fountain </p>
                            <p>The Royal Parks were keen to restore the fountains and horse troughs in Richmond Park.  These facilities had been well used by people, horses and dogs.
                            </p>
                            <p> The first fountain selected for restoration was the one by Kingston Gate and, in addition to general refurbishment the fountain needed a replacement lid.  The fountain was dismantled so that the material used to dowel the cup to the bowl could be checked and the overflow drainage reinstated.  The urn was then found to be badly cracked and needed to be replaced.  
                                The drinking fountain was successfully installed with a new paved surround in February 2002.
                            </p>
                        </div>
                    </div>

              

      </Tab>
      <Tab eventKey="profile" title="Nepal">


                       <div>
                            <h2>Nepal</h2>
                            <p class="lead">Janakalyan Secondary School, School and the Water Supply Project. </p>
                            <p>Janakalyan Secondary School was established 82 years ago in the regime of Rana Dynasty in Nepal to provide a religious education to the upper class ‘Brahmin’ people of the region, but the infrastructure does not still coincide with the requirements needed for the educational system of Nepal.     </p>
                            <p> The school used to use water from the contaminated well which is a long walk away from the school untill last year. At that time a staff had to carry buckets of water for drinking purpose and for the use of toilets. The school had to appoint an assistant (peon) just to carry water and serve the teacher and students. Although the water had to boil and chill before it was ready for drinking.
                            </p>
                            <p> The School Management Committee with the coordination of BYC constructed the water supply system for the school with the help of Resolve International. The water supply scheme used a spring source located at higher altitudes. The water was conveyed by pipes from the sources to the small reservoir located at the corner of the school premises through gravity flow.
                            </p>
                            <p> Then the water further conveyed to the stand post and to the toilets. The stand post was located in the central part of the school so that each block had easy access on it. The water was sufficient for the current use of school. The local people use another source of water in the village for their household uses. The spring water from the high altitude forest remains perennial through out the year. 
                            </p>
                            <p>
                            The author received lots of positive and optimistic feedback from teachers, staff, students and local people. The Head Teacher Mr Hari Prasad Sharma mentioned that “the school had to drink non-potable water from the well and the toilets have no water at all. A bucket of water at toilet was not enough for few students. Teachers had to look after the students for studies as well as the water problem”. He further added that “the two stand posts for drinking purpose and tap water connection at toilet have given a far most relief to the students and teachers”. 
                            </p>

                        </div>
                        <img src="./janakalyan.jpg" alt="graphic" class="img-fluid rounded align-self-start mr-lg-5 mb-5 mb-lg-0"></img>
                

      </Tab>
      <Tab eventKey="contact" title="Zambia">
                  <img src="./mambwa.jpg" alt="graphic" class="img-fluid rounded align-self-start mr-lg-5 mb-5 mb-lg-0"></img>
                        <div>
                            <h2>Zambia</h2>
                            <p class="lead">Mambwa, Zambia </p>
                            <p>In June 2008 the Drinking Fountain Association donated £500 towards the work of Village Water; providing wells, sanitation equipment and hygiene education to rural communities in Zambia.
                            </p>
                            <p> 
                                    The donation made by the Drinking Fountain Association was used as a contribution towards the cost of a well and sanitation programme in Mambwa in the Western Province of Zambia. This well is currently benefiting 200 people. The residents of Mumbwa are excited to have the new well. You will see the picture I have included of the children of the village. Before the well was installed they walked for two hours each day with their mothers to collect dirty water from a small open waterway.
                            </p>
                            <p>
                                    The main house you can see in the pictures shelters the new water well and pump from the weather and wandering animals. The annexe you can see protects the surplus water soakaway where they will plant a banana tree. To the other side of the main house is a pots and pans drying rack for use when washing up after cooking and eating.
                            </p>
                        </div>
      </Tab>
    </Tabs> 


        </div> {/*  // end PROTECT CONTAINER  */}


    </div>
       {/*  // end .section  */}



       <div class="section" id="getinvolved">

<div class="container">
    <div class="row">
        <div class="col-md-6">
            <img src="./fountainRegents.jpg" alt="Regents Park Fountain" class="img-fluid"></img>
        </div>
        <div class="col-md-6 d-flex align-items-center">
            <div>
                <p></p>
                <h3 className="h3-purple">Get Involved</h3>
                <p class="mb-4">If you think there should be more drinking fountains, then learn how you can get involved: </p>
               
        </div>
    </div>

  </div>

  </div>
</div>
 {/*  // end .section  */}


 <div class="section light-bg">

<div class="container">
    <div class="row">
        <div class="col-md-8 d-flex align-items-center">
            <ul class="list-unstyled ui-steps">
                <li class="media">
                    <div class="circle-icon mr-4">1</div>
                    <div class="media-body">
                        <h5>Write to your Council</h5>
                        <p>The installation of drinking fountains is usually the responsibility of local authorities. Drop an email to your local authority or to your elected local councillors. </p>
                    </div>
                </li>
                <li class="media my-4">
                    <div class="circle-icon mr-4">2</div>
                    <div class="media-body">
                        <h5>Help us map the world's Fountains</h5>
                        <p>Our website contains a database showing where the UK's fountains are located. If you know of a fountain that is not shown in our database, you can upload its details via "Add a Fountain". </p>
                    </div>
                </li>
                <li class="media">
                    <div class="circle-icon mr-4">3</div>
                    <div class="media-body">
                        <h5>Spread the word</h5>
                        <p>Encourage your friends to use drinking fountains. Like us on Facebook and Instagram. </p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="col-md-4">
            <img src="./king_square.jpg" alt="king_square_fountain_islington" class="img-fluid"></img>
        </div>

    </div>

</div>

</div>
{/*  // end .section  */}





    

<div className="container" id="trustees">
            <p></p>
            <div className="section-title">
                <small>TRUSTEES</small>
                <h3 className="h3-purple">Trustees</h3>
            </div>





      <Row xs={1} md={2} className="g-4">
    
        <Col>
          <Card>
            <Card.Img variant="top" src="./t-johnMills.jpg" />
            <Card.Body>
              <Card.Title>Sir John Mills</Card.Title>
              <Card.Text>
              His formative years were spent in both the countryside and London, the latter during the Blitz, and retains his interest in both rural and urban life. Served as an officer in the Royal Air Force on a short service commission and started his own practice as an architect in 1960 absorbing subsequently a number of other practices, and gradually expanded his work internationally. The first practice he took over was Brewer Smith & Brewer, founded in 1840 and thereafter retained the name for historic reasons. Remains in practice as Managing Director of the Brewer Smith & Brewer Group. Member of the Committee of the Association since 1977 and Chairman since the early 1980's.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>


        <Col>
          <Card>
            <Card.Img variant="top" src="./t-ralphBaber.jpg" />
            <Card.Body>
              <Card.Title>Ralph Baber</Card.Title>
              <Card.Text>
              Ralph has a BSc(hons) degree, is a qualified Chartered Accountant and is a Member of the Securities Institute. He has served on a number of regulatory panels including the SFA appeals tribunal. Ralph was a manager at Price Waterhouse until he left to join a client, CAL Futures Limited, first as Finance Director and then, from 1987, as Managing Director. The company was a futures and options broker and a hedge fund manager. In 1995 the business was sold to Union plc where Ralph initially became an executive director. In 1997 he was promoted to Chief Executive. In 1998 Union plc bought WorldInvest, which had £1.6 billion of equity funds under management. In 2001 Union plc was acquired by a private group. Ralph founded Slater Investments with Mark Slater in 1994. He initially served as a non-executive director and now acts as Chief Operating Officer.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card>
            <Card.Img variant="top" src="./t-alastairKing.jpg" />
            <Card.Body>
              <Card.Title>Alastair King</Card.Title>
              <Card.Text>
              An English qualified solicitor, Alastair King, was previously a senior associate at the international law firm Baker & McKenzie, where he specialised in major domestic and cross-border merger and acquisition transactions, initial public offerings and debt issues.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

     

        <Col>
          <Card>
            <Card.Img variant="top" src="./t-markElliot.jpg" />
            <Card.Body>
              <Card.Title>Mark Elliot</Card.Title>
              <Card.Text>
              Mark is a Chartered Accountant and has been a Trustee since 1995. He has close associations with the City of London through work and his Livery Company, the Worshipful Company of Clockmakers of which he was Master in 2012 and remains an active member of the Court. He is also a Trustee of the National Benevolent Society of Watch and Clockmakers and of the Clockmakers Charity.
              </Card.Text>
             
            </Card.Body>
          </Card>
        </Col>



        <Col>
          <Card>
            <Card.Img variant="top" src="./t-lauraErith.jpg" />
            <Card.Body>
              <Card.Title>Laura Erith</Card.Title>
              <Card.Text>
              2002 – Enfants Terribles – Fancy Dress Clothes.  Selling to toys shops, fairs etc. Was previously a Tribal Arts expert at Sothebys. Married in 1996 and has three children. 
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

     

        <Col>
          <Card>
            <Card.Img variant="top" src="./t-sophieFuller.jpg" />
            <Card.Body>
              <Card.Title>Sophie Fuller</Card.Title>
              <Card.Text>
              Sophie Fuller is a direct descendant of Samuel Gurney, one of the founding members of DFA, and succeed her mother Isabel de Pelet BEM (nee Cazenove) as a trustee in 2004. She is a veterinary surgeon (RVC 1999) and currently works in South West London. Mother to three boys, who enjoys a busy life between London & Somerset where she is able to indulge in her passion for the countryside.
              </Card.Text>
              
            </Card.Body>
          </Card>
        </Col>
    
    </Row>

</div>

<ApplyForGrantSection></ApplyForGrantSection>
    
    <p></p>

<GrantsFAQs></GrantsFAQs>


    <Carousel slide={false}>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./first.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>Our First Fountain</h3>
          <p>---</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/trough.jpg"
          alt="Second slide"
        />

        <Carousel.Caption>
          <h3>London Fountain</h3>
          <p>----</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./hyde-landscape.png"
          alt="Third slide"
        />

        <Carousel.Caption>
          <h3>Hyde Park</h3>
          <p>
            --
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>

<div id="annualreports">
    <AnnualReports></AnnualReports>
    </div>

</>
  );




};
