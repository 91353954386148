/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_appsync_graphqlEndpoint": "https://i2ji2srrizh7zgkqxlsb4oik6a.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-2:0b470673-e686-4ceb-bf0b-62a6b3a4e104",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_K3tMTDVvH",
    "aws_user_pools_web_client_id": "7l8m4438rs1vb1755r3oc8ah27",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "dersonalfountains62c978231742467dbb0a6bdabe570784745-staging",
    "aws_user_files_s3_bucket_region": "eu-west-2",
    "geo": {
        "amazon_location_service": {
            "region": "eu-west-1",
            "maps": {
                "items": {
                    "map7c406bff-staging": {
                        "style": "VectorEsriStreets"
                    }
                },
                "default": "map7c406bff-staging"
            },
            "search_indices": {
                "items": [
                    "placeIndex8b500e89-staging"
                ],
                "default": "placeIndex8b500e89-staging"
            }
        }
    }
};


export default awsmobile;
