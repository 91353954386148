// import { Heading } from '@aws-amplify/ui-react';
import React, { useState, useEffect } from "react";

export function Verboten( ) {


  //const [wotUserTypedForSearch, setWotUserTypedForSearch] = useState("");


  function logMe() {

    console.log("LOG ME! ");
  }


  //function handleSearchClick() {
  //  console.log("handleSeacrhClick()! ");
  // }

  

  return (
    <div>
     
      You are not in an admin group so are FORBIDDEN to see any content.
      
    </div>
  );
}
