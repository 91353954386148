import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useAuthenticator, View } from '@aws-amplify/ui-react';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
export function Login() {
  console.log("This is Login.js. Use the useAuthenticator HOOK to access and modify the AUTH STATE! ");
  console.log("A route can be set to signIn, signOut depending on where user is in auth flow");
  const { route } = useAuthenticator((context) => [context.route]); // context callback func.
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || '/';

  useEffect(() => {
    console.log("useEffect in Login.js - when route changes to authenticated, user will be navigated to previous location");
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);
  return (
<>
    
<header className="bg-gradient-poc short-please" id="fountains">
        <div className="container mt-5">
            <h1>Login To Add A Fountain</h1>
               </div>
       
    </header>

    <View className="auth-wrapper">
      <Authenticator></Authenticator>
    </View>

</>
  );
}
