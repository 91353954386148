// components/Layout.js
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';


import { useState, useEffect } from 'react';


import { useAuthenticator, Button, Heading, View } from '@aws-amplify/ui-react';
import Navbar from './Navbar';

// 
// RETURNS ALL THE TOP NAV STUFF. 
export function Layout() {


  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("This is useEffect of Layout.js");
  }, [] );

  function logOut() {
    signOut();
    navigate('/login');
  }

// <div class="nav-menu fixed-top"><div class="container"><div class="row"><div class="col-md-12"><nav class="navbar navbar-dark navbar-expand-lg"><a class="navbar-brand" href="index.html"></a><button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span> </button><div class="collapse navbar-collapse" id="navbar"><ul class="navbar-nav ml-auto"><li class="nav-item"> <a class="nav-link active" href="#home">OBJECTIVES<span class="sr-only">(current)</span></a> </li><li class="nav-item"> <a class="nav-link" href="#history">HISTORY</a> </li><li class="nav-item"> <a class="nav-link" href="#whatwedo">RESTORATION</a> </li><li class="nav-item"> <a class="nav-link" href="#gallery">GALLERY</a> </li><li class="nav-item"> <a class="nav-link" href="#grants">GRANTS</a> </li><li class="nav-item"> <a class="nav-link" href="#gallery">FIND-A-FOUNTAIN</a> </li><li class="nav-item"> <a class="nav-link" href="#faqs">FAQs</a> </li><li class="nav-item"><a href="#contact" class="btn btn-outline-light my-3 my-sm-0 ml-lg-3">CONTACT</a></li></ul></div></nav></div></div></div></div>
// <div class="container"><div class="row"><div class="col-md-12"><nav class="navbar navbar-dark navbar-expand-lg"><a class="navbar-brand" href="index.html"></a><button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span> </button><div class="collapse navbar-collapse" id="navbar"><ul class="navbar-nav ml-auto"><li class="nav-item"> <a class="nav-link active" href="#home">OBJECTIVES<span class="sr-only">(current)</span></a> </li><li class="nav-item"> <a class="nav-link" href="#history">HISTORY</a> </li><li class="nav-item"> <a class="nav-link" href="#whatwedo">RESTORATION</a> </li><li class="nav-item"> <a class="nav-link" href="#gallery">GALLERY</a> </li><li class="nav-item"> <a class="nav-link" href="#grants">GRANTS</a> </li><li class="nav-item"> <a class="nav-link" href="#gallery">FIND-A-FOUNTAIN</a> </li><li class="nav-item"> <a class="nav-link" href="#faqs">FAQs</a> </li><li class="nav-item"><a href="#contact" class="btn btn-outline-light my-3 my-sm-0 ml-lg-3">CONTACT</a></li></ul></div></nav></div></div></div> 
// <nav class="navbar navbar-dark navbar-expand-lg"><a class="navbar-brand" href="index.html"></a><button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span> </button><div class="collapse navbar-collapse" id="navbar"><ul class="navbar-nav ml-auto"><li class="nav-item"> <a class="nav-link active" href="#home">OBJECTIVES<span class="sr-only">(current)</span></a> </li><li class="nav-item"> <a class="nav-link" href="#history">HISTORY</a> </li><li class="nav-item"> <a class="nav-link" href="#whatwedo">RESTORATION</a> </li><li class="nav-item"> <a class="nav-link" href="#gallery">GALLERY</a> </li><li class="nav-item"> <a class="nav-link" href="#grants">GRANTS</a> </li><li class="nav-item"> <a class="nav-link" href="#gallery">FIND-A-FOUNTAIN</a> </li><li class="nav-item"> <a class="nav-link" href="#faqs">FAQs</a> </li><li class="nav-item"><a href="#contact" class="btn btn-outline-light my-3 my-sm-0 ml-lg-3">CONTACT</a></li></ul></div></nav>
  return (
    <>
     
    {/*  <Heading level={1}>Layout.js - HeadING Level 1 - POC Example Auth Routes App</Heading> */}
     
     {/*} <View>
        {route === 'authenticated' ? 'You are logged in!' : 'Please Login!'}
        </View> */}

      <Outlet />

     
 {/*  fixed-top comes from bootstap - see node_modules/boot   */}
      <div class="nav-menu fixed-top">
        <div class="container">
         <div class="row">
          <div class="col-md-12">

            <nav id="over-boot-padding" class="navbar navbar-dark navbar-expand-lg " >

            <a class="navbar-brand" href="index.html"></a> 

         {/*   <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
        
              <span class="navbar-toggler-icon"></span> 
              </button>
      */}

              <Navbar>xxx</Navbar>

           


                {/* nav is the row of navigation buttons links!  */}
                {/* base roote */}
         {/*      <Button onClick={() => navigate('/')}>Home</Button>    */}
              {/* CHECK App.js to find Route path= */} 
                {/* <Button onClick={() => navigate('/map')}>Map</Button> */}

              {/* here we specify what urls will be shown when stuff clicked and also what urls to use to access */}
               {/* <Button onClick={() => navigate('/fountains')}>Fountains</Button> */}
               {/* <Button onClick={() => navigate('/protected')}>
                Protected Create New
              </Button>   */}
               {/* <Button onClick={() => navigate('/admin')}> 
                Admin
              </Button>  */}

              {/* curleys are the ESCAPE HATCH INTO JS ... so this is an if statement */}
                {/* TERNARY OPERATOR ... cond ? wot2do if tru : wot2do if false */}
                  {/* if NOT auth then show Login btn tab, otherwise show Logout btn tab */}

 {/* LAYOUT - WAS USED FOR THE TOP NAVBAR IN PROTECTED ROUTES ... but we use diff top bar*/}

        {/*      {route !== 'authenticated' ? (
                <Button onClick={() => navigate('/login')}>Login</Button>
              ) : (
                <Button onClick={() => logOut()}>Logout</Button>
              )}

              */}



            </nav>
          </div>
          </div>
          </div>
          </div>
    </>
  );
}