import React, { useState } from 'react';



// ...
const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        console.log("THIS is handleMenuToggle!");
        console.log(`call setIsMenuOpen to be opposite of what it is: `+isMenuOpen);
        setIsMenuOpen(!isMenuOpen);
      }
  // ...
  return (
    <>
      {/* . THIS HAS ALL THE TOP BAR NAV STUFF.. */}
     


      <div className={`nav-menu ${isMenuOpen ? 'menu-is-open' : ''}`}>

      <button onClick={handleMenuToggle} class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
        
        <span class="navbar-toggler-icon"></span> 
        </button>

    
     {/*  add the show word depending on state ... */}
     

{/*  div with id navbar (which contains menu links) is ALWAYS THERE but is display: none?  */}
      <div id="navbar" className={`collapse  navbar-collapse ${isMenuOpen ? 'show' : ''}`}>

    



                            <ul class="navbar-nav ml-auto">
                            <li class="nav-item"> <a class="nav-link" href="/">HOME</a>  </li>
                                <li class="nav-item"> <a class="nav-link" href="/fountains">FOUNTAINS <span class="sr-only"></span></a> </li>
                                <li class="nav-item"> <a class="nav-link" href="/protected">ADD FOUNTAIN</a> </li>
                                <li class="nav-item"> <a class="nav-link" href="/map">MAP</a> </li>
                                <li class="nav-item"> <a class="nav-link" href="./#history">HISTORY</a> </li>
                                <li class="nav-item"> <a class="nav-link" href="./#whatwedo">ABOUT US</a> </li>
                                <li class="nav-item"> <a class="nav-link" href="./#trustees">TRUSTEES</a> </li>
                                <li class="nav-item"> <a class="nav-link" href="./#grants">GRANTS</a> </li>
                               <li class="nav-item"> <a class="nav-link" href="/admin">ADMIN</a> </li>
                                <li class="nav-item"> <a class="nav-link" href="./#annualreports">ANNUAL REPORTS</a> </li>
                                
                                <li class="nav-item"><a href="#contact" class="btn btn-outline-light my-3 my-sm-0 ml-lg-3">CONTACT</a></li>
                              
                            </ul>
                        </div>


     

     
    </div>
  
    </>
  );
};

export default Navbar;
