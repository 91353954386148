import React from 'react';
//import './YourStyleSheet.css'; // Replace with your actual stylesheet path

const ApplyForGrantSection = () => {
    return (
        <div className="container" id="grants">
            <p></p>
            <div className="section-title">
                <small>GRANTS</small>
                <h3 className="h3-purple">Types of Grant</h3>
            </div>


        <div className="paul-cards-container">
                
                <div className="paul-card">
        
                        <div className="paul-card-head-top-part-of-card">  
                            <div className="paul-pink-text paul-font-size-40pc" >SCHOOLS</div>
                                
                            <span className="price">SCHOOLS</span>
                        </div>

                        <ul className="list-group list-group-flush">
                            <div className="paul-list-group-item">Install fountain in a school</div>
                            <div className="paul-list-group-item">School fountain model</div>
                        </ul>
                        
                        <div className="paul-card-padding-around-button">
                            <a href="forms/school-pack.doc" className="btn btn-primary btn-lg btn-block">Apply for Grant</a>
                        </div>
                </div>

      <div className="paul-card">
        <div className="paul-card-head-top-part-of-card">  
            <div className="paul-pink-text paul-font-size-40pc" >OVERSEAS</div>          
            <span className="price">OVERSEAS</span>
                       
        </div>

                <ul className="list-group list-group-flush">
                    <div className="paul-list-group-item">Install fountain outside the UK</div>
                    <div className="paul-list-group-item">Apply for an overseas grant</div>
                </ul>
                
                <div className="paul-card-padding-around-button">
                    <a href="forms/new%20grant-application%20(Overseas).pdf" className="btn btn-primary btn-lg btn-block">Apply for Grant</a>
                </div>
      </div>

      <div className="paul-card">
                        <div className="paul-card-head-top-part-of-card">
                            <div className="paul-pink-text paul-font-size-40pc" >UK</div>
                            <span className="price">UK</span>
                        </div>

                        <ul className="list-group list-group-flush">
                            <div className="paul-list-group-item">Install fountain in the UK (non-school)</div>
                            <div className="paul-list-group-item">Apply for a grant to install a fountain in the UK</div>
                        </ul>
                        
                        <div className="paul-card-padding-around-button">
                            <a href="forms/new%20grant-application.pdf" className="btn btn-primary btn-lg btn-block">Apply for Grant</a>
                        </div>
                </div>
      <div className="paul-card">
                        <div className="paul-card-head-top-part-of-card">  
                            <div className="paul-pink-text paul-font-size-40pc" >RESTORATION</div>          
            
                            <span className="price">RESTORE</span>
                        </div>

                        <ul className="list-group list-group-flush">
                            <div className="paul-list-group-item">Restore an existing fountain</div>
                            <div className="paul-list-group-item">Apply for a grant to restore an existing fountain</div>
                        </ul>
                        
                        <div className="paul-card-padding-around-button">
                            <a href="forms/new%20grant-application%20(Restoration).pdf" className="btn btn-primary btn-lg btn-block">Apply for Grant</a>
                        </div>
                </div>
        </div>

           
    </div>
    );
};

export default ApplyForGrantSection;
