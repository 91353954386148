export const sculptureList= [
   { id: "1",
      name: 'THIS IS THE NAME OF THE FIRST ELEMENT in the ARR of OBJECTS',
artist:'Marta andrade',
description: 'fgfghfhfg gfhgfhgfjhfgj ghfghjfghf ',
url: 'ww.wri.st',
alt: 'ghjgjg hjfkjhgjhk hjghjg '
} , 
{ id: "2",
   name: 'NAME OF SECOND ELEMENT IN THE ARR OF OBJECTS',
artist:'poce',
description: 'ddddedededede ',
url: 'ww.waltzing.st',
alt: 'ddd ddd dddd  '
} , 
{ id: "3",
   name: 'THIRD!',
artist:'dan',
description: 'dermo ',
url: 'ww.deepsst',
alt: 'dalt dalt  '
} 
]