// components/Home.js
import React, { useState, useEffect } from "react";
import "../App.css";

import { getList, setItem } from './../services/call-api';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import { API } from "aws-amplify";

// npm start

//import { useEffect } from 'react'; useEffect for stuff you want when page first loads. 

const arrYesils = [{"eventKey":"“What can there be left for a commander in defeat? In antiquity they took poison” - German Field Marshal Model, before shooting himself in the head."},{"eventKey":"The Battle of Hurtgen Forest began. It would drag on for months and cost the lives of 33,000 American soldiers."},{"eventKey":"Having spent three years fighting on the Eastern Front, Hitler moved Field Marshal Walter Model to the Western Front to defend France against the Allies."},{"eventKey":"The losing British General at Saratoga, John Burgoyne, was later dubbed ‘the man who lost America’."},{"eventKey":"New York City based film Breakfast At Tiffany’s was released"}];


function convertMonthIntToMonthStr(monthInt) {

  if(monthInt === 1) {
    return "Jan "
  }else if(monthInt === 2) {
    return "Feb "
  }else if(monthInt === 3) {
    return "Mar "
  }else if(monthInt === 4) {
    return "Apr "
  }else if(monthInt === 5) {
    return "May "
  }else if(monthInt === 6) {
    return "Jun "
  }else if(monthInt === 7) {
    return "Jul "
  }else if(monthInt === 8) {
    return "Aug "
  }else if(monthInt === 9) {
    return "Sep "
  }else if(monthInt === 10) {
    return "Oct "
  }else if(monthInt === 11) {
    return "Nov "
  }else if(monthInt === 12) {
    return "Dec ";
  } else {
    return "";
  }
}


function howManyYearsAgo(yearInt) {

  const yearsAgo = 2023 - yearInt;

    return "" + yearsAgo + " years ago"

}

export function Slideshow({ signOut }) {
//const Unp = ({ signOut }) => {
  console.log("sTHIS IS Slideshow.js COMPONENT!");
  // SET UP STATE !!! VIP VIP VIP
  const [yesilCounter, setYesilCounter] = useState(0);
  const [counter, setCounter] = useState(0);
  const [counterLessOne, setCounterLessOne] = useState(0);

  const [count, setCount] = useState(0);

  const [visibleYesil, setYesil] = useState([]);

  // API ... 
  const [alert, setAlert] = useState(false);
  const [itemInput, setItemInput] = useState('');
  const [list, setList] = useState([]);




  // useEffect seems to be stuff you call for FIRST RENDER
  // example, go fetch data you need to show! 
  // 2nd parameter of useEffect often seems to be empty array.
  useEffect(() => {
    console.log("THIS is useEffect for SET UP 4 INITIAL RENDER");

    //updateYesilShown();

   

   
    }, []); // Or [] if effect doesn't need props or state


    useEffect(() => {

      console.log("SECOND USEEFFECT - for getting API DATA! ");
      let mounted = true;
      getList()
        .then(dataFromApi => {
          if(mounted) {
            console.log("data returned by api was");
            console.log(dataFromApi);
            console.log(dataFromApi.items); // if api returns an object containing array of objects { items = [] } so first peel of items wrapper to get to array of objects.
            setList(dataFromApi.items)


            const interval = setInterval(() => {
              // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/Arrow_functions
              setCounter((prevCounter) => {
                console.log("Inside useEffect setInterval - prevCounter");
                console.log(prevCounter);
                if(prevCounter == dataFromApi.items.length-1) {
                  return  0
                } else {
                  return prevCounter % dataFromApi.items.length  + 1}
                }
              );
        
              // arrow func ... function(previousCounter) { return prevCounter % arrYesils.length  + 1}
              
            }, 30000);
        
            return () => clearInterval(interval);



          }
        })
      return () => mounted = false;
  
     // if(alert) {
     //   setTimeout(() => {
     //     setAlert(false);
     //   }, 1000)
     // }
  
      
    }, []) // empty array implies no useEffect DEPENDENCIES (call useEffect when they change)
    
   

  return (
    <>
    <header className="bg-gradient-poc short-please" id="fountains">
        <div className="container mt-5">
            {/*   <h1>Trustees Slideshow</h1> */}
               </div>
    </header>

    <Container>
      <Row>
        <Col>  {list.length ? howManyYearsAgo(list[counter].yearInt) : ""}</Col>
      </Row>
    </Container>


  <div className="App">
     
     <div>

      <h1>
      {list.length ? convertMonthIntToMonthStr(list[counter].monthInt) + list[counter].yearInt : "loading ..."}
      </h1>
      </div>
      <h1>
      {list.length ? list[counter].eventKey : "loading ..."}
      </h1>
      <h5>Counter: {counter}</h5>

     
    </div>

   

 
      </>
  );
};

