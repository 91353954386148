//import { useAuthenticator, Heading } from '@aws-amplify/ui-react';
import React, { useState, useEffect, useMemo } from "react"; //useMemo for media queries? 

import {
  Button,
  Flex,
  Heading,
  Text,
  Image,
  TextField,
  View,
  withAuthenticator,
  useAuthenticator
} from "@aws-amplify/ui-react";

import {
  createDersonalFountain as createFountainMutation,
  deleteDersonalFountain as deleteFountainMutation,
} from "../graphql/mutations";

import { API, Auth, Storage } from "aws-amplify";

// EXPORT WHERE THE ACTION IS! 
// EXPORT MARKS THE SPOT!!! 
// args passed in via   <ProtectedCreate fetchPersonalFountains=
export function ProtectedCreate( props ) {


  const { route } = useAuthenticator((context) => [context.route]);

  const message =
    route === 'authenticated' ? 'FIRST PROTECTED ROUTE!' : 'Loading...';


    // useEffect seems to be stuff you call for FIRST RENDER
  // example, go fetch data you need to show! 
  // 2nd parameter of useEffect often seems to be empty array - if empty means only call after 1st render.
  // if non-empty array it means if ANYTHING in the array of dependencies changes then call again! 
  useEffect( () => {
    console.log("THIS IS useEffect - called 1st time AND if change to dependency state (e.g. [nextToken]");
    //console.log("From useEffect - Call callBulkCreate!");
    console.log("this useEffect has NO dependency [] so only called 1st RENDER!");

    async function fetchUserData() {
      // You can await here
    const user = await Auth.currentAuthenticatedUser();
    const accessToken = user.signInUserSession.accessToken
    console.log(`accessToken is: ${accessToken}`);

    console.log(`accessToken.payload is: ${Object.keys(accessToken.payload)}`);

    //console.log(`accessToken.payload is: ${accessToken.payload["cognito:groups"][0]}`);
      // ...

      if (accessToken && accessToken.payload && accessToken.payload["cognito:groups"] && accessToken.payload["cognito:groups"].length > 0) {
        console.log(`accessToken.payload is: ${accessToken.payload["cognito:groups"][0]}`);
      } else {
        console.error("cognito:group not found in the accessToken payload. USER not a member of any groups.ie not an admin.");
      }
      

    }
    fetchUserData();

  
    // accessToken.payload["cognito:groups"][0]
    //callBulkCreate();
  }, []); // you may want to call fetchPersonalFountains inside this useEffect when state nextToken changes when user clicks next or prev


 

    // async function createFountain(event) {
    async function callApiToCreateFountainThenUpdateUiUsingSet(event) {

      console.log("THIS is createFountain! Lets GRAB data from form via form.get ")
      
      const query2use = createFountainMutation;
      
      event.preventDefault();
      const form = new FormData(event.target);

      const districtRaw = form.get("District").toLowerCase();
      const districtFirstLetterCaps = districtRaw.charAt(0).toUpperCase() + districtRaw.slice(1)
      
      const StreetNameFromFormLowerCased = form.get("StreetName").toLowerCase();
      //const districtFirstLetterCaps = StreetNameRawFromForm.charAt(0).toUpperCase() + districtRaw.slice(1)
      

      const cityRaw = form.get("CityTown").toLowerCase();
      const cityFirstLetterCaps = cityRaw.charAt(0).toUpperCase() + cityRaw.slice(1)
      
      const image = form.get("image");
      console.log("img from form is:");
      console.log(image);
      const imageName = image.name;
      const imageNameNoSpaces = imageName.replace(/ /g, '_');

      // eg File {name: '', lastModified: 1711217055143, lastModifiedDate: Sat Mar 23 2024 18:04:15 GMT+0000 (Greenwich Mean Time), webkitRelativePath: '', size: 0, …}

      // used in input: data ...
      const data = {
        Location: form.get("Location"),
        CityTown: cityFirstLetterCaps,
        District: districtFirstLetterCaps,
        StreetName: StreetNameFromFormLowerCased,
        Type: "DersonalFountain",
        Verified: "y",
        ImageUrl: imageNameNoSpaces
      };

      console.log(`data being passed into query ${query2use} as input is: `);
      console.log(data);
  
     // await API.graphql({
     //   query: createFountainMutation,
     //   authMode:"AMAZON_COGNITO_USER_POOLS",
     //   variables: { input: data },
     // });

     if (!!image.name) await Storage.put(imageNameNoSpaces, image) .then(result => {console.log("OK", result)});

                await API.graphql({ 
                  query: createFountainMutation,
                  authMode:"AMAZON_COGNITO_USER_POOLS",
                  variables: { input: data },
                })
              .then( (apiDataResp) => { 
                console.log(".THEN( !");


                console.log("Added OK! ");
                
                console.log(apiDataResp);

                //alert("Fountain Added");

              }
                ).catch(


                  // possible errors "Variable 'input' has coerced Null
                  error => {console.log("There has been an error in query createFountainMutation");
                  console.log("UH OH - NOT added ");
                  console.log(error)
                  // alert("There has been a problem adding the fountain")
                  alert(`There has been a problem adding the fountain \n\n${error.errors[0].message} \n\n ${query2use}`)
                }

                  
                ) // end of catch


      // means REACH BACK INTO THE CALLER (or parent) and use THE func or var defined there.
      // props.fetchPersonalFountains();
      // WHEN TESTING COMMENT OUT 2 EASILY SEE API ERROS!
      //event.target.reset();
    }



    const click4bulkupload = () => {
      console.log("click4bulkupload .");
      console.log("COMMENTED OUT - callBulkCreate");
      // callBulkCreate();
    }

    // bulk 
        function callBulkCreate() {

        //  const data = {
        //    name: 'BNAMO', 
        //    description: 'BDESCO',
        //    city: 'BCITYO', 
        //    district: 'BDISTRICTO', 
        //    site: 'BSITEO',
        //    type: "PersonalFountain",
        //    verified: "y"
        //  }

            // for bulk
          const arrayOfFountainObjects = [
            {Location:"In Town Walton on Walton on Thames UDC", District: "Elmbridge",  CityTown: "London",  PostcodeZip: "",  County: "",  Country: "England",  GoogleMapLink: "",  Latitude:null,  Longitude:null, What3words: "",  Name: "In Town Walton on",  Description: "Trough 1c design 9ft 6in",  Material: "Granolithic",  Year: 1909, Type: "DersonalFountain", Verified: "y",  DfaDateMonth: "3rd Septmeber",  DfaYear: 1909,  DfaLocation: "Walton on Thames",  DfaCity: "London",  DfaCounty: "",  DfaPostcodeZip: "",  DfaCountry: "England",  DfaMapReference: "",  DfaDescription: "Trough 1c design 9ft 6in",  DfaMaterial: "Granolithic",  DfaErectedBy: "Association",  DfaSite: "In Town Walton on",  DfaLondonRecord: "1807 Book2",  DfaDistrict: "UDC",  DfaWaterCompany: "UDC",  DfaSuppliedBy: "UDC",  DfaRemarks: "Presented by association cost",  DfaFurtherRemarks: "",  SubmittedBy: "DFA Original Database"} ,

            {Location:"Regentys Park Zoological Society Zoological", District: "Camden",  CityTown: "Unknown",  PostcodeZip: "",  County: "",  Country: "",  GoogleMapLink: "",  Latitude:null,  Longitude:null, What3words: "",  Name: "Regentys Park",  Description: "Drinking fountain no dog trough 124c Bubble",  Material: "Granolithic",  Year: 1929, Type: "DersonalFountain", Verified: "y",  DfaDateMonth: "11th December",  DfaYear: 1929,  DfaLocation: "Zoological Society",  DfaCity: "",  DfaCounty: "",  DfaPostcodeZip: "",  DfaCountry: "",  DfaMapReference: "",  DfaDescription: "Drinking fountain no dog trough 124c Bubble",  DfaMaterial: "Granolithic",  DfaErectedBy: "Gardens",  DfaSite: "Regentys Park",  DfaLondonRecord: "2431 Book3",  DfaDistrict: "Zoological",  DfaWaterCompany: "Metropolitan",  DfaSuppliedBy: "",  DfaRemarks: "The gift of the association",  DfaFurtherRemarks: "",  SubmittedBy: "DFA Original Database"} ,
            
            {Location:"Regents Park by Zoological Society Zoological", District: "Camden",  CityTown: "Unknown",  PostcodeZip: "",  County: "",  Country: "",  GoogleMapLink: "",  Latitude:null,  Longitude:null, What3words: "",  Name: "Regents Park by",  Description: "Drinking fountain no dog trough 124c Bubble",  Material: "Granolithic",  Year: 1930, Type: "DersonalFountain", Verified: "y",  DfaDateMonth: "25th February",  DfaYear: 1930,  DfaLocation: "Zoological Society",  DfaCity: "",  DfaCounty: "",  DfaPostcodeZip: "",  DfaCountry: "",  DfaMapReference: "",  DfaDescription: "Drinking fountain no dog trough 124c Bubble",  DfaMaterial: "Granolithic",  DfaErectedBy: "Gardens",  DfaSite: "Regents Park by",  DfaLondonRecord: "2439 Book3",  DfaDistrict: "Zoological",  DfaWaterCompany: "Metropolitan",  DfaSuppliedBy: "",  DfaRemarks: "The gift of the association",  DfaFurtherRemarks: "",  SubmittedBy: "DFA Original Database"} 
            
          ]

          //const data2 = {Location:"NEW NEW Plaistow No 5 ", District: "West Ham",  CityTown: "London",  PostcodeZip: "",  County: "",  Country: "England",  GoogleMapLink: "",  Latitude:null,  Longitude:null, What3words: "",  Name: "North Street",  Description: "Cattle trough and dog trough design 1a 6ft 6in HB",  Material: "Granite",  Year: null, Type: "DersonalFountain", Verified: "y",  DfaDateMonth: "",  DfaYear: null,  DfaLocation: "Plaistow No 5 ",  DfaCity: "London",  DfaCounty: "",  DfaPostcodeZip: "",  DfaCountry: "England",  DfaMapReference: "",  DfaDescription: "Cattle trough and dog trough design 1a 6ft 6in HB",  DfaMaterial: "Granite",  DfaErectedBy: "Association",  DfaSite: "North Street",  DfaLondonRecord: "747 Book1",  DfaDistrict: "",  DfaDistrict: "West Ham",  DfaWaterCompany: "East London",  DfaSuppliedBy: "Association",  DfaRemarks: "The gift of Mrs Graser Saiole.  Removed to Victoria Street March 1882 and No 7 erected instead",  DfaFurtherRemarks: ""} 
          
          for (var i = 0; i < arrayOfFountainObjects.length; i++) {
            console.log("About to call createOneFountain")
            createOneFountain(arrayOfFountainObjects[i]);
          }
          

        }
    
        // async function createFountain(event) {
          // used BY BULK ...
          async function createOneFountain(dataOneFountain) {

            console.log("THIS is createOneFountain! ")

            const query2use = createFountainMutation;
            
            // used in input: data ...
            // Dot .NAME OF TextField (.e.g .CityTown)
            const data = {
              
              CityTown: dataOneFountain.CityTown,
              District: dataOneFountain.District,
              Location: dataOneFountain.Location,
              Type: "DersonalFountain",
              Verified: "y"
            };
      
            console.log("data being passed into query as input is: ");
            console.log(dataOneFountain);
        
           // await API.graphql({
           //   query: createFountainMutation,
           //   authMode:"AMAZON_COGNITO_USER_POOLS",
           //   variables: { input: data },
           // });
      
                      await API.graphql({ 
                        query: query2use,
                        authMode:"AMAZON_COGNITO_USER_POOLS",
                        variables: { input: dataOneFountain },
                      })
                    .then( (apiDataResp) => { 
                      console.log(".THEN( !");
      
      
                      console.log("A A A A A A A A A A A A A A A A A A ");
                      console.log("ADDED ADDED ADDED ADDED ADDED ADDED ");
                      

                      console.log(apiDataResp);
      
                     // alert("Fountain Added");
      
                    }
                      ).catch(
      
      
                        // possible errors "Variable 'input' has coerced Null
                        error => {console.log("There has been an ERROR! in query createFountainMutation");
                        console.log(error);
                        console.log(error.errors[0].message);

                        // If you try to push a field that does NOT exist in the DB you get:
                        // The variables input contains a field that is not defined for input object type 'CreatePersonalFountainInput'
                        alert(`There has been a problem adding the fountain \n\n${dataOneFountain.Location} \n\n${error.errors[0].message} \n\n${query2use}`)
                      }
      
                        
                      )
      
      
        
          }

  

     // IF ONLY ONE LINE RETURN DOESN'T NEED RETURN();
  return ( <>

  {/* IN THE AWS AMPLIFY PROTECTED ROUTES SAMPLE THE PROTECTED ROUTE JUST HAD A HEADING */}

  <header className="bg-gradient-poc short-please" id="fountains">
        <div className="container mt-5">
            <h1>Add a Fountain</h1>
               </div>
       
    </header>

 {/*  PROTECTED ROUTES SAMPLE DID NOT HAVE CREATE BELOW - I ADDED! */}
  
  {/* MOVE stuff that was in App.js in 12345 into respective relevant components!  */}
  <View as="form" margin="3rem 0" onSubmit={callApiToCreateFountainThenUpdateUiUsingSet}>
 
        <Flex direction="row" justifyContent="center">


         {/* The name field used to access info (e.g. .Location) VIA form.get("Location")  */}
        <TextField
            name="Location"
            placeholder="Location"
            label="Fountain Location"
            labelHidden
            variation="quiet"
            required
          />

        
        <TextField
            name="CityTown"
            placeholder="City"
            label="Fountain City"
            labelHidden
            variation="quiet"
            required
          />

<TextField
            name="StreetName"
            placeholder="StreetName"
            label="StreetName"
            labelHidden
            variation="quiet"
            required
          />

        <TextField
            name="District"
            placeholder="District"
            label="District"
            labelHidden
            variation="quiet"
            required
          />


<Flex direction="column" justifyContent="center" alignItems="center">
  <label htmlFor="imageUpload" style={{ marginBottom: '1rem' }}>Upload Image</label>
  <input
    id="imageUpload"
    name="image"
    type="file"
    style={{ marginBottom: '1rem' }}
  />
</Flex>



          <Button type="submit" variation="primary">
            Create Fountain
          </Button>
        </Flex>
    
</View>
  

 {/* <div onClick={click4bulkupload}>BUTTON FOR BULK UPLOAD</div> */}
  
</>
  );
}
