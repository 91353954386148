// RequireAuth.js
import { useLocation, Navigate } from 'react-router-dom';
// components/ProtectSecond.js
import { useAuthenticator, Heading } from '@aws-amplify/ui-react';

import React, { useState, useEffect, useMemo } from "react"; //useMemo for media queries? 

import { Auth } from "aws-amplify";



import { AdminContent } from './AdminContent';
import { Verboten } from './Verboten';






export function RequireGroup( {children} ) {

  // EACH ROUTE SEEMS2 NEED THIS route = useAuth CONTEXT thingy
  const { route } = useAuthenticator((context) => [context.route]);

  // STATE VARS ...
  const [groupOfUser, setGroupOfUser] = useState(null);



    // useEffect seems to be stuff you call for FIRST RENDER
  // example, go fetch data you need to show! 
  // 2nd parameter of useEffect often seems to be empty array - if empty means only call after 1st render.
  // if non-empty array it means if ANYTHING in the array of dependencies changes then call again! 
  useEffect( () => {
    console.log("THIS IS useEffect - called 1st time AND if change to dependency state (e.g. [nextToken]");
    //console.log("From useEffect - Call callBulkCreate!");
    console.log("this useEffect has NO dependency [] so only called 1st RENDER!");

    async function fetchUserData() {
      // You can await here
    const user = await Auth.currentAuthenticatedUser();
    const accessToken = user.signInUserSession.accessToken
    console.log(`accessToken is: ${accessToken}`);

    console.log(`accessToken.payload is: ${Object.keys(accessToken.payload)}`);
    if (accessToken.payload && accessToken.payload["cognito:groups"] && accessToken.payload["cognito:groups"].length > 0) {
      console.log(`accessToken.payload is: ${accessToken.payload["cognito:groups"][0]}`);
      setGroupOfUser(accessToken.payload["cognito:groups"][0]);
     
    } else {
      console.log("accessToken.payload or cognito:groups is undefined or empty");
    }
     // ...
    }


    fetchUserData();

  
    // accessToken.payload["cognito:groups"][0]
    //callBulkCreate();
  }, []); // you may want to call fetchPersonalFountains inside this useEffect when state nextToken changes when user clicks next or prev


  /// if the route variable holds the value 'authenticated', the message will be 'SECOND PROTECTED ROUTE!'. Otherwise, it will be 'Loading...'. 
  const message =
    route === 'needtobeingroup' ? 'GROUP PROTECTED ROUTE!' : 'Loading...';

  // return JSX ...
  // return <Heading level={1}>{message}</Heading>;
 
  if (groupOfUser !== 'userpoolgroup4amp4american') {
  //if (groupOfUser == "userpoolgroup4amp4american") {
    console.log("Is a NOT an admin");
    // NOTE TECHNIQUE OF HAVING MULTIPLE RETURN keywords:
    return (
        <Verboten />
    );
  }
    else {
    console.log("IS an admin - return children ie. things inside <Parent> <ComponentsHereAreChildren> </Parent>")
    return children
    }


}




