/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDersonalFountain = /* GraphQL */ `
  query GetDersonalFountain($id: ID!) {
    getDersonalFountain(id: $id) {
      id
      Type
      Verified
      Location
      StreetName
      Boto
      PostcodeZip
      District
      CityTown
      County
      Country
      GoogleMapLink
      Longitude
      Latitude
      What3words
      Name
      Description
      Material
      ImageUrl
      Key4s3Image
      SubmittedBy
      Year
      DfaDateMonth
      DfaYear
      DfaLocation
      DfaSite
      DfaCity
      DfaCounty
      DfaDistrict
      DfaPostcodeZip
      DfaCountry
      DfaMapReference
      DfaDescription
      DfaMaterial
      DfaErectedBy
      DfaLondonRecord
      DfaWaterCompany
      DfaSuppliedBy
      DfaRemarks
      DfaFurtherRemarks
      Drinking
      Trough
      IsWorking
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listDersonalFountains = /* GraphQL */ `
  query ListDersonalFountains(
    $filter: ModelDersonalFountainFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDersonalFountains(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Type
        Verified
        Location
        StreetName
        Boto
        PostcodeZip
        District
        CityTown
        County
        Country
        GoogleMapLink
        Longitude
        Latitude
        What3words
        Name
        Description
        Material
        ImageUrl
        Key4s3Image
        SubmittedBy
        Year
        DfaDateMonth
        DfaYear
        DfaLocation
        DfaSite
        DfaCity
        DfaCounty
        DfaDistrict
        DfaPostcodeZip
        DfaCountry
        DfaMapReference
        DfaDescription
        DfaMaterial
        DfaErectedBy
        DfaLondonRecord
        DfaWaterCompany
        DfaSuppliedBy
        DfaRemarks
        DfaFurtherRemarks
        Drinking
        Trough
        IsWorking
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const fountainsSortedByCityTown = /* GraphQL */ `
  query FountainsSortedByCityTown(
    $Type: String!
    $CityTown: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDersonalFountainFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fountainsSortedByCityTown(
      Type: $Type
      CityTown: $CityTown
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Type
        Verified
        Location
        StreetName
        Boto
        PostcodeZip
        District
        CityTown
        County
        Country
        GoogleMapLink
        Longitude
        Latitude
        What3words
        Name
        Description
        Material
        ImageUrl
        Key4s3Image
        SubmittedBy
        Year
        DfaDateMonth
        DfaYear
        DfaLocation
        DfaSite
        DfaCity
        DfaCounty
        DfaDistrict
        DfaPostcodeZip
        DfaCountry
        DfaMapReference
        DfaDescription
        DfaMaterial
        DfaErectedBy
        DfaLondonRecord
        DfaWaterCompany
        DfaSuppliedBy
        DfaRemarks
        DfaFurtherRemarks
        Drinking
        Trough
        IsWorking
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const fountainsSortedByDistrict = /* GraphQL */ `
  query FountainsSortedByDistrict(
    $Verified: String!
    $District: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDersonalFountainFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fountainsSortedByDistrict(
      Verified: $Verified
      District: $District
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Type
        Verified
        Location
        StreetName
        Boto
        PostcodeZip
        District
        CityTown
        County
        Country
        GoogleMapLink
        Longitude
        Latitude
        What3words
        Name
        Description
        Material
        ImageUrl
        Key4s3Image
        SubmittedBy
        Year
        DfaDateMonth
        DfaYear
        DfaLocation
        DfaSite
        DfaCity
        DfaCounty
        DfaDistrict
        DfaPostcodeZip
        DfaCountry
        DfaMapReference
        DfaDescription
        DfaMaterial
        DfaErectedBy
        DfaLondonRecord
        DfaWaterCompany
        DfaSuppliedBy
        DfaRemarks
        DfaFurtherRemarks
        Drinking
        Trough
        IsWorking
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const byBotoHashAndStreetNameSortKey = /* GraphQL */ `
  query ByBotoHashAndStreetNameSortKey(
    $Boto: String!
    $StreetName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDersonalFountainFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byBotoHashAndStreetNameSortKey(
      Boto: $Boto
      StreetName: $StreetName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Type
        Verified
        Location
        StreetName
        Boto
        PostcodeZip
        District
        CityTown
        County
        Country
        GoogleMapLink
        Longitude
        Latitude
        What3words
        Name
        Description
        Material
        ImageUrl
        Key4s3Image
        SubmittedBy
        Year
        DfaDateMonth
        DfaYear
        DfaLocation
        DfaSite
        DfaCity
        DfaCounty
        DfaDistrict
        DfaPostcodeZip
        DfaCountry
        DfaMapReference
        DfaDescription
        DfaMaterial
        DfaErectedBy
        DfaLondonRecord
        DfaWaterCompany
        DfaSuppliedBy
        DfaRemarks
        DfaFurtherRemarks
        Drinking
        Trough
        IsWorking
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
