/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDersonalFountain = /* GraphQL */ `
  mutation CreateDersonalFountain(
    $input: CreateDersonalFountainInput!
    $condition: ModelDersonalFountainConditionInput
  ) {
    createDersonalFountain(input: $input, condition: $condition) {
      id
      Type
      Verified
      Location
      StreetName
      Boto
      PostcodeZip
      District
      CityTown
      County
      Country
      GoogleMapLink
      Longitude
      Latitude
      What3words
      Name
      Description
      Material
      ImageUrl
      Key4s3Image
      SubmittedBy
      Year
      DfaDateMonth
      DfaYear
      DfaLocation
      DfaSite
      DfaCity
      DfaCounty
      DfaDistrict
      DfaPostcodeZip
      DfaCountry
      DfaMapReference
      DfaDescription
      DfaMaterial
      DfaErectedBy
      DfaLondonRecord
      DfaWaterCompany
      DfaSuppliedBy
      DfaRemarks
      DfaFurtherRemarks
      Drinking
      Trough
      IsWorking
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateDersonalFountain = /* GraphQL */ `
  mutation UpdateDersonalFountain(
    $input: UpdateDersonalFountainInput!
    $condition: ModelDersonalFountainConditionInput
  ) {
    updateDersonalFountain(input: $input, condition: $condition) {
      id
      Type
      Verified
      Location
      StreetName
      Boto
      PostcodeZip
      District
      CityTown
      County
      Country
      GoogleMapLink
      Longitude
      Latitude
      What3words
      Name
      Description
      Material
      ImageUrl
      Key4s3Image
      SubmittedBy
      Year
      DfaDateMonth
      DfaYear
      DfaLocation
      DfaSite
      DfaCity
      DfaCounty
      DfaDistrict
      DfaPostcodeZip
      DfaCountry
      DfaMapReference
      DfaDescription
      DfaMaterial
      DfaErectedBy
      DfaLondonRecord
      DfaWaterCompany
      DfaSuppliedBy
      DfaRemarks
      DfaFurtherRemarks
      Drinking
      Trough
      IsWorking
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteDersonalFountain = /* GraphQL */ `
  mutation DeleteDersonalFountain(
    $input: DeleteDersonalFountainInput!
    $condition: ModelDersonalFountainConditionInput
  ) {
    deleteDersonalFountain(input: $input, condition: $condition) {
      id
      Type
      Verified
      Location
      StreetName
      Boto
      PostcodeZip
      District
      CityTown
      County
      Country
      GoogleMapLink
      Longitude
      Latitude
      What3words
      Name
      Description
      Material
      ImageUrl
      Key4s3Image
      SubmittedBy
      Year
      DfaDateMonth
      DfaYear
      DfaLocation
      DfaSite
      DfaCity
      DfaCounty
      DfaDistrict
      DfaPostcodeZip
      DfaCountry
      DfaMapReference
      DfaDescription
      DfaMaterial
      DfaErectedBy
      DfaLondonRecord
      DfaWaterCompany
      DfaSuppliedBy
      DfaRemarks
      DfaFurtherRemarks
      Drinking
      Trough
      IsWorking
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
