// components/Protected.js
import { useAuthenticator, Heading } from '@aws-amplify/ui-react';
import { Outlet, useNavigate } from 'react-router-dom';

import React, { useState, useEffect, useMemo } from "react"; //useMemo for media queries? 


export function AdminContent() {


  const { route } = useAuthenticator((context) => [context.route]);


  useEffect( () => {
    console.log("THIS IS useEffect - ADmin content");
    //console.log("From useEffect - Call callBulkCreate!");
 

  });


  const { signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const navigate = useNavigate();



  function logOut() {
    signOut();
    navigate('/login');
  }

  const message =
    route === 'authenticated' ? 'ADMIN CONTENT PROTECTED ROUTE!' : 'Loading...';



  return (
  <>
    <header className="bg-gradient-poc short-please" id="fountains">
    <div className="container mt-5">
        <h1>Add a Fountain</h1>
           </div>
   
</header>
  
  <div>Hey there!</div>

  <br></br>
      <br></br>
      <button onClick={logOut}  type="submit"> Log Out </button>

  
  </>
  )

 
}