/* eslint-disable */
/* COPY FROM aut0generated queries THEN TWEAK and give new name! */


export const listFountainsLimitedHeaders = /* GraphQL */ `
  query ListFountainsLimitedHeaders(
    $filter: ModelDersonalFountainFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDersonalFountains(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Location
        District
        CityTown
        County
        Country
        id
      }
      nextToken
      __typename
    }
  }
`;


// use the name after export const in the query: xxx
// use the name after query (lowercase) to extract from the API response (E.G. data.fountainsSortedByDistrict)
// THE LATER is driven by queryField: "fountainsSortedByDistrict",
// id is INTENTIONALLY LAST ... that's the one you clik on to see SINGLE fountain!
export const fountainsSortedByDistrictLimitedAttributes = /* GraphQL */ `
  query FountainsSortedByDistrict(
    $Verified: String!
    $District: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDersonalFountainFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fountainsSortedByDistrict(
      Verified: $Verified
      District: $District
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Location
        District
        CityTown
        createdAt
        id
      }
      nextToken
      __typename
    }
  }
`;



// use the name after export const in the query: xxx
// use the name after query (lowercase) to extract from the API response (E.G. data.fountainsSortedByDistrict)
// THE LATER is driven by queryField: "fountainsSortedByDistrict",
export const fountainsSortedByCityTownLimitedAttributes = /* GraphQL */ `
  query FountainsSortedByCityTown(
    $Type: String!
    $CityTown: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDersonalFountainFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fountainsSortedByCityTown(
      Type: $Type
      CityTown: $CityTown
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Location
        District
        CityTown
        Description
        createdAt
        id
      }
      nextToken
      __typename
    }
  }
`;



