import React from 'react';
//import './YourStyleSheet.css'; // Replace with your actual stylesheet path

const AnnualReports = () => {
    return (
<div className="container">
    <p></p>
    <div className="section-title">
        <small>ANNUAL REPORTS</small>
        <h3 className="h3-purple">Annual Reports</h3>
    </div>


    <div className="paul-cards-container">
                
        <div className="paul-card">

                <div className="paul-card-head-top-part-of-card">  
                    <div className="paul-pink-text paul-font-size-40pc" >2012</div>
                        
                    <span className="price">2012</span>
                </div>

                <ul className="list-group list-group-flush">
                    <div className="paul-list-group-item">Annual Reports</div>
                    
                </ul>
                
                <div className="paul-card-padding-around-button">
                    <a href="forms/school-pack.doc" className="btn btn-primary btn-lg btn-block">DOWNLOAD</a>
                </div>
        </div>

        <div className="paul-card">
        <div className="paul-card-head-top-part-of-card">  
            <div className="paul-pink-text paul-font-size-40pc" >2013</div>          
            <span className="price">2013</span>
                        
        </div>

                <ul className="list-group list-group-flush">
                    <div className="paul-list-group-item">Annual Report</div>
                </ul>
                
                <div className="paul-card-padding-around-button">
                    <a href="forms/school-pack.doc" className="btn btn-primary btn-lg btn-block">DOWNLOAD</a>
                </div>
        </div>

        <div className="paul-card">
                        <div className="paul-card-head-top-part-of-card">
                            <div className="paul-pink-text paul-font-size-40pc" >2014</div>
                            <span className="price">2014</span>
                        </div>

                        <ul className="list-group list-group-flush">
                            <div className="paul-list-group-item">Annual Report</div>
                        </ul>
                        
                        <div className="paul-card-padding-around-button">
                            <a href="forms/accounts_2014.pdf" className="btn btn-primary btn-lg btn-block">DOWNLOAD</a>
                        </div>
        </div>


        <div className="paul-card">
                        <div className="paul-card-head-top-part-of-card">  
                            <div className="paul-pink-text paul-font-size-40pc" >2015</div>          
            
                            <span className="price">2015</span>
                        </div>

                        <ul className="list-group list-group-flush">
                            <div className="paul-list-group-item">Annual Report</div>
                            
                        </ul>
                        
                        <div className="paul-card-padding-around-button">
                            <a href="forms/accounts_2015.pdf" className="btn btn-primary btn-lg btn-block">DOWNLOAD</a>
                        </div>
        </div>

        <div className="paul-card">

                <div className="paul-card-head-top-part-of-card">  
                    <div className="paul-pink-text paul-font-size-40pc" >2016</div>
                        
                    <span className="price">2016</span>
                </div>

                <ul className="list-group list-group-flush">
                    <div className="paul-list-group-item">Annual Report</div>
                </ul>
                
                <div className="paul-card-padding-around-button">
                    <a href="forms/accounts_2016.pdf" className="btn btn-primary btn-lg btn-block">DOWNLOAD</a>
                </div>
        </div>

        <div className="paul-card">
        <div className="paul-card-head-top-part-of-card">  
            <div className="paul-pink-text paul-font-size-40pc" >2017</div>          
            <span className="price">2017</span>
                        
        </div>

                <ul className="list-group list-group-flush">
                    <div className="paul-list-group-item">Annual Report</div>
                   
                </ul>
                
                <div className="paul-card-padding-around-button">
                    <a href="forms/accounts_2017.pdf" className="btn btn-primary btn-lg btn-block">DOWNLOAD</a>
                </div>
        </div>

        <div className="paul-card">
                        <div className="paul-card-head-top-part-of-card">
                            <div className="paul-pink-text paul-font-size-40pc">2018</div>
                            <span className="price">2018</span>
                        </div>

                        <ul className="list-group list-group-flush">
                            <div className="paul-list-group-item">Annual Report</div>
                            
                        </ul>
                        
                        <div className="paul-card-padding-around-button">
                            <a href="forms/DFA_Annual_Accounts_2018_finalised.pdf" className="btn btn-primary btn-lg btn-block">DOWNLOAD</a>
                        </div>
        </div>


        <div className="paul-card">
                        <div className="paul-card-head-top-part-of-card">  
                            <div className="paul-pink-text paul-font-size-40pc" >2019</div>          
            
                            <span className="price">2019</span>
                        </div>

                        <ul className="list-group list-group-flush">
                            <div className="paul-list-group-item">Annual Report</div>
                           
                        </ul>
                        
                        <div className="paul-card-padding-around-button">
                            <a href="forms/DFA_Annual_Accounts_2019_filed_with_the_Charity_Commission.pdf" className="btn btn-primary btn-lg btn-block">DOWNLOAD</a>
                        </div>
        </div>


        <div className="paul-card">

                <div className="paul-card-head-top-part-of-card">  
                    <div className="paul-pink-text paul-font-size-40pc" >2020</div>
                        
                    <span className="price">2020</span>
                </div>

                <ul className="list-group list-group-flush">
                    <div className="paul-list-group-item">Annual Report</div>
                   
                </ul>
                
                <div className="paul-card-padding-around-button">
                    <a href="forms/DFA_Annual_Accounts%202020_signed%20accountants.pdf" className="btn btn-primary btn-lg btn-block">DOWNLOAD</a>
                </div>
        </div>

        <div className="paul-card">
        <div className="paul-card-head-top-part-of-card">  
            <div className="paul-pink-text paul-font-size-40pc" >2021</div>          
            <span className="price">2021</span>
                        
        </div>

                <ul className="list-group list-group-flush">
                    <div className="paul-list-group-item">Annual Report</div>
                   
                </ul>
                
                <div className="paul-card-padding-around-button">
                    <a href="forms/DFA_Annual_Accounts_2021_final.pdf" className="btn btn-primary btn-lg btn-block">DOWNLOAD</a>
                </div>
        </div>

        <div className="paul-card">
                        <div className="paul-card-head-top-part-of-card">
                            <div className="paul-pink-text paul-font-size-40pc" >2022</div>
                            <span className="price">2022</span>
                        </div>

                        <ul className="list-group list-group-flush">
                            <div className="paul-list-group-item">Annual Report</div>
                           
                        </ul>
                        
                        <div className="paul-card-padding-around-button">
                            <a href="forms/DFA_Annual_Accounts_2022_Final_signed_AK.pdf" className="btn btn-primary btn-lg btn-block">DOWNLOAD</a>
                        </div>
        </div>


        <div className="paul-card">
                        <div className="paul-card-head-top-part-of-card">  
                            <div className="paul-pink-text paul-font-size-40pc" >2023</div>          
            
                            <span className="price">2023</span>
                        </div>

                        <ul className="list-group list-group-flush">
                            <div className="paul-list-group-item">Annual Report</div>
                            
                        </ul>
                        
                        <div className="paul-card-padding-around-button">
                            <a href="forms/school-pack.doc" className="btn btn-primary btn-lg btn-block">PENDING</a>
                        </div>
        </div>


    </div>

           
</div>
);
};

export default AnnualReports;
